import browserDetect from '../browser-detect/browser-detect';
import { isValidDate } from '../../utils/date/date';

let form;
let datePicker;
let formFields;
let submitBtn;
let checkboxes;
let genderLabels;
let kidsToggle;

function init() {
	form = document.querySelector('[js-module~="profileCreate"]');

	if (!form) return;

	submitBtn = form.querySelector('button[type=submit]');
	datePicker = form.querySelector('[js-module~="datepicker"]');
	formFields = form.querySelectorAll(
		'input:not([type=hidden]):not([js-module~="datepicker"]), textarea, select'
	);
	checkboxes = form.querySelectorAll(
		'[js-module~="checkbox"] input[type="checkbox"]'
	);
	kidsToggle = form.querySelector('[js-element~="profileKidsToggle"]');
	genderLabels = form.querySelectorAll('[js-element~="profileGenderLabel"]');

	addEventListeners();
	setPrefilledDate();

	toggleSubmitButton(validateForm(true));
}

function addEventListeners() {
	datePicker.addEventListener('input', handleDateChange);

	formFields.forEach((field) => {
		field.addEventListener('input', handleInputChange);
	});

	checkboxes.forEach((checkbox) => {
		checkbox.addEventListener('input', toggleHighlight.bind(null, checkbox));
	});

	if (kidsToggle) {
		kidsToggle.addEventListener('change', (e) => {
			toggleGenderLabel(e.currentTarget.checked);
		});
	}

	form.addEventListener('submit', handleSubmit);
}

// Listeners and handlers
function handleSubmit(event) {
	if (!validateForm(true)) {
		event.preventDefault();
	} else {
		// to avoid double clicks
		submitBtn.setAttribute('disabled', 'disabled');
	}
}

function handleDateChange() {
	validateDatePicker();
	validateForm(true);

	const kidsProfileCheckbox = document.querySelector(
		'[js-module~="kidsProfile"] input[type="checkbox"]'
	);
	if (!isMainProfile() && kidsProfileCheckbox) {
		if (hasMinimumAge()) {
			kidsProfileCheckbox.checked = false;
			toggleGenderLabel(false);
		} else {
			toggleGenderLabel(true);
			kidsProfileCheckbox.checked = true;
		}
	}
}

function handleInputChange() {
	validateField(this);
	validateForm(true);
}

// Validation

function validateForm(silent) {
	let formIsValid = true;

	formFields.forEach((field) => {
		const fieldIsValid = validateField(field, silent);
		if (!fieldIsValid) {
			formIsValid = false;
		}
	});

	const datePickerIsValid = validateDatePicker(silent);

	if (!datePickerIsValid) {
		formIsValid = false;
	}

	toggleSubmitButton(formIsValid);

	return formIsValid;
}

function validateField(field, silent) {
	const fieldIsValid = field.checkValidity();

	if (!silent) setFieldValidity(field, fieldIsValid);

	return fieldIsValid;
}

function validateDatePicker(silent) {
	const filledInDate = new Date(getDateStringValue(datePicker));
	if (!isValidDate(filledInDate)) return false;

	const ageIsValid = isMainProfile()
		? hasMinimumAge()
		: dateIsInPast(filledInDate);

	if (!silent) setFieldValidity(datePicker, ageIsValid);

	return ageIsValid;
}

function isMainProfile() {
	return datePicker.dataset.profileType === 'main';
}

function hasMinimumAge() {
	const filledInDate = new Date(getDateStringValue(datePicker));

	if (!isValidDate(filledInDate)) return false;

	const minimumAge = isMainProfile()
		? parseInt(datePicker.dataset.minAge, 10)
		: 13;
	const expectedAge = new Date(filledInDate).setFullYear(
		filledInDate.getFullYear() + minimumAge
	);

	return dateIsInPast(expectedAge);
}

function dateIsInPast(date) {
	return date <= new Date();
}

// Element manipulations
function toggleSubmitButton(valid) {
	if (valid === false) {
		submitBtn.setAttribute('disabled', 'disabled');
	} else {
		submitBtn.removeAttribute('disabled');
	}
}

function setFieldValidity(field, valid) {
	const parent = field.closest('.form__group');

	parent.classList.toggle('is-invalid', !valid);
}

function setPrefilledDate() {
	if (isDateSupported()) return;

	// check the value on page load
	if (datePicker.value !== '') {
		const parseDate = new Date(datePicker.value);
		const dateYear = parseDate.getFullYear();
		const dateMonth = ('0' + (parseDate.getMonth() + 1)).slice(-2);
		const dateDay = ('0' + parseDate.getDate()).slice(-2);
		const convertedVal = dateDay + '/' + dateMonth + '/' + dateYear;
		datePicker.value = convertedVal;
	}
}

function toggleHighlight(el) {
	const checkboxWrapper = el.closest('[js-module~="checkbox"]');

	if (checkboxWrapper.classList.contains('is-active')) {
		removeActiveState(checkboxWrapper);
	} else {
		setActiveState(checkboxWrapper);
	}
}

function toggleGenderLabel(isKids) {
	if (isKids) {
		genderLabels.forEach((label) => {
			label.textContent =
				window.i18n.profile.label[`${label.getAttribute('for')}Kids`];
		});
	} else {
		genderLabels.forEach((label) => {
			label.textContent = window.i18n.profile.label[label.getAttribute('for')];
		});
	}
}

function setActiveState(wrapper) {
	wrapper.classList.add('is-active');
}

function removeActiveState(wrapper) {
	wrapper.classList.remove('is-active');
}

// Utils Safari
function isDateSupported() {
	return browserDetect.isDateInputSupported();
}

// Turn the human readable date into a machine readable date
function getDateStringValue(el) {
	const dateString = el.value;

	if (isDateSupported()) return dateString;

	const date = dateString.split('/');
	return date.reverse().join('-');
}

export default {
	init,
};
