import Modal from '../../classes/modal/modal';

import profilesTracking from './profiles.tracking';

let form, button;

function init() {
	button = document.querySelector('[js-module~="profileDelete"]');
	if (!button) return;
	form = button.form;

	const html = document
		.querySelector('#deleteProfileModalTemplate')
		?.content.cloneNode(true);

	if (!html) return;

	button.addEventListener('click', function (e) {
		new Modal(
			'DeleteProfileModal',
			{
				message: html,
			},
			{
				onOpen: confirm,
			}
		);
		e.preventDefault();
	});
}

function confirm() {
	profilesTracking.init();
	window.DeleteProfileModal.querySelector(
		'[js-element~="profileDeleteConfirm"]'
	).addEventListener(
		'click',
		() => {
			form.submit();
		},
		false
	);
}

export default {
	init,
	confirm,
};
