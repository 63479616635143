/* globals popcornPlayer */
import LRU from '../../classes/lru/lru';
import browsersniffing from '../../utils/browsersniffing/browsersniffing';
import { debounce } from '../../utils/throttle/debounce';

let feedListData;
let fullscreenchange;
let contextData;
let wHeight;
let scrollYAtPageLoad;
let playerCache;
let visiblePlayers;
let playersBounds;
let activePlayer;
let storedVolume;
let players;
let speed;
let scrollingTimeout;
let speedThreshold;
let intersectionThreshold;

async function init() {
	feedListData = null;
	fullscreenchange = false;
	wHeight = window.innerHeight;
	scrollYAtPageLoad = 0;
	activePlayer = null;
	storedVolume = null;
	// no feed on mobile devices
	if (browsersniffing.detectMobileOS()) {
		return;
	}

	window.dataLayer = window.dataLayer || [];

	window.addEventListener('resize', debounce(resizeHandler, 100), false);

	// Fullscreen changes trigger a resize
	document.addEventListener('fullscreenchange', fullscreenHandler);

	function fullscreenHandler() {
		if (document.fullscreenElement) {
			fullscreenchange = true;
		} else {
			fullscreenchange = false;
		}
	}

	const shortformFeed = document.querySelector('[js-module~="shortformFeed"]');

	if (shortformFeed) {
		const players = shortformFeed.querySelectorAll('[js-module~="feedPlayer"]');

		const trackingData = shortformFeed.dataset?.tracking
			? JSON.parse(shortformFeed.dataset?.tracking)
			: null;

		feedListData = {
			ab_group: trackingData?.abGroup,
			id: null,
			name: 'Trending',
			orientation: null,
			position: 1,
			provider: trackingData?.provider,
			request_id: trackingData?.requestId,
			routing_group: null,
		};

		if (players.length) {
			const promises = [
				popcornPlayer.loadPlugin(popcornPlayer.PluginName.Heartbeat),
				popcornPlayer.loadPlugin(popcornPlayer.PluginName.GTM),
				popcornPlayer.loadPlugin(popcornPlayer.PluginName.CIM),
				popcornPlayer.loadPlugin(popcornPlayer.PluginName.Mux),
				popcornPlayer.loadScript(popcornPlayer.ScriptName.GemiusPlayer),
			];

			await Promise.all(promises);
		}

		shortFormPlayerFeed();
	}
}

function resizeHandler() {
	// if the fullscreen change was the cause of the resize event, abort
	if (fullscreenchange) {
		fullscreenchange = false;
		return;
	}

	wHeight = window.innerHeight;
	playerCache = null;
	playerCache = new LRU(10, { onDelete: destroyPlayer }); // max 10 items in the LRU cache
	playersBounds = []; // emtpy the array
	getPlayerElementProperties();
	customIntersectionObserver();
}

function shortFormPlayerFeed() {
	activePlayer = null;
	storedVolume = null;
	visiblePlayers = [];
	playersBounds = [];
	playerCache = new LRU(10, { onDelete: destroyPlayer }); // max 10 items in the LRU cache
	const playButtons = document.querySelectorAll(
		'[js-element~="feedPlayerButton"]'
	);
	players = document.querySelectorAll('[js-module~="feedPlayer"]');
	const dummyPlayButtons = document.querySelectorAll(
		'[js-element~="cardImpressionTracker"]'
	);
	speed = 0;
	scrollingTimeout;
	speedThreshold = 10;
	intersectionThreshold = 1; // maybe change it later to 0.66 or 0.5
	scrollYAtPageLoad = window.scrollY;
	getPlayerElementProperties();
	customIntersectionObserver();
	addScrollEventListener();

	// in case autoplay and player doesn't load via the intersectionObserver you
	// can still click to start
	if (playButtons.length > 0) {
		playButtons.forEach((button) => {
			button.addEventListener(
				'click',
				() => {
					const player = button.parentElement;
					loadPlayer(player, player.dataset.index);
				},
				{ passive: true }
			);
		});
	}

	// impression tracking for the SEO (anonymous user) version
	if (dummyPlayButtons.length > 0) {
		dummyPlayButtons.forEach((button) => {
			button.addEventListener(
				'click',
				() => {
					sendPlayIntentionTracking(button);
				},
				{ passive: true }
			);
		});
	}

	addTrackingEventListeners();
}

function addScrollEventListener() {
	document.addEventListener(
		'scroll',
		() => {
			// we are scrolling
			speed = checkScrollSpeed();
			if (speed > 0 && speed <= speedThreshold) {
				customIntersectionObserver();
			}
			clearTimeout(scrollingTimeout);
			// if scrolling is done we trigger a callback after XXms
			scrollingTimeout = setTimeout(() => {
				customIntersectionObserver();
			}, 41);
		},
		{ passive: true }
	);
}

const checkScrollSpeed = (function () {
	let lastPos,
		newPos,
		timer,
		delta,
		delay = 50; // ms (higher means lower fidelity)

	function clear() {
		lastPos = null;
		delta = 0;
	}

	clear();

	return function () {
		newPos = window.scrollY;
		if (lastPos !== null) {
			delta = newPos - lastPos;
		}
		lastPos = newPos;
		clearTimeout(timer);
		timer = setTimeout(clear, delay);
		return delta < 0 ? delta * -1 : delta;
	};
})();

function customIntersectionObserver() {
	if (players?.length) {
		players.forEach((player, i) => {
			const rect = playersBounds[i];
			const index = Number(player.dataset.index);

			// check if the element is intersecting and store it in a property and update the visiblePlayers array
			if (isIntersecting(player, rect)) {
				if (!visiblePlayers.includes(index)) {
					visiblePlayers.push(index);

					// if this player hasn't been loaded before
					if (!playerCache.cache.has(index)) {
						loadPlayer(players[index], index);
					}
				}
				player.isIntersecting = true;
				sendImpressionTracking(player);
			} else if (isNotIntersecting(player, rect)) {
				if (visiblePlayers.includes(index)) {
					visiblePlayers.splice(visiblePlayers.indexOf(index), 1);
				}
				player.isIntersecting = false;
			}
		});

		// now that we know which players/elements are visible and are loaded
		// we now 'autoplay' them
		playManager();
	}
}

function getPlayerElementProperties() {
	if (!players?.length) {
		return;
	}

	players.forEach((player) => {
		Object.defineProperty(player, 'isIntersecting', {
			value: false,
			writable: true,
		});
		const rect = player.getBoundingClientRect();
		playersBounds.push({
			top: rect.top,
			bottom: rect.top + rect.height,
			height: rect.height,
		});
	});
}

function loadPlayer(el, index) {
	const playerContainer = el.querySelector(
		'[js-element~="feedPlayerContainer"]'
	);
	contextData = window.playerConfig;
	const player = new popcornPlayer.Player(playerContainer, {
		containerAspectRatio: 16 / 9,
		controls: {
			language: contextData.uiLanguage,
			keyboardNavigation: 'focusOnly',
		},
		device: {
			name: 'browser',
		},
	});

	if (!player) return;

	if (typeof index === 'string') {
		index = Number(index);
	}

	playerCache.put(index, player); // add to the LRU cache

	player.addEventListener(popcornPlayer.Events.LoadStatusChange, (event) => {
		if (event.to === popcornPlayer.LoadStatus.Loaded) {
			const tracks = player.textTracks;
			const defaultTrack = tracks.find(
				(track) =>
					track.language === window.playerConfig.uiLanguage && !track.variant
			);
			if (defaultTrack) player.selectTextTrack(defaultTrack.id);

			playManager();
		}
	});

	player.addEventListener(popcornPlayer.Events.PlayheadStateChange, (event) => {
		if (event.to === popcornPlayer.PlayheadState.Playing) {
			activePlayer = index;
			playerCache.update(index); // update the LRU cache
		}

		if (event.to === popcornPlayer.PlayheadState.Ended) {
			// start next visible player, if any
			if (visiblePlayers.length > 1) {
				playPlayer(visiblePlayers[visiblePlayers.indexOf(activePlayer) + 1]);
			}
		}
	});

	player.addEventListener(popcornPlayer.Events.VolumeChange, () => {
		addVolumeEventListeners(player, index);
	});

	player.loadFromButter(
		{
			// butterParams
			apiKey: contextData.apiKey,
			id: playerContainer.dataset.id,
			authToken: contextData.token,
			env: contextData.environment,
			unstable_userIdForAdvertising: false,
			userId: contextData.accountId,
			zone: contextData.zone,
		},
		{
			// playerParams
			allowMutedAutoPlay: false, // for Safari
			autoPlay: false, // we fake autoplay with play method
			controls: {
				posterImageUrl: window.App.players[playerContainer.dataset.id].poster,
			},
			tracking: {
				gtm: {
					dataLayerName: 'dataLayer',
				},
			},
			volume: storedVolume ? storedVolume : 0,
		}
	);
}

// decides which player starts playing (after they're loaded)
function playManager() {
	// pause players that are no longer intersecting
	players.forEach((player) => {
		if (!player.isIntersecting) {
			pausePlayer(player.dataset.index);
		}
	});

	playPlayer(visiblePlayers[0]);
}

function playPlayer(index) {
	if (!playerCache.cache.has(index)) {
		return;
	}

	const playerInCache = playerCache.cache.get(index);
	if (
		playerInCache.playheadState === popcornPlayer.PlayheadState.Paused ||
		playerInCache.playheadState === popcornPlayer.PlayheadState.Ended ||
		playerInCache.loadStatus === popcornPlayer.LoadStatus.Loaded
	) {
		playerInCache.play(false);
	}
}
function pausePlayer(index) {
	if (typeof index === 'string') {
		index = Number(index);
	}

	if (playerCache.cache.size === 0 || !playerCache.cache.has(index)) return;

	if (
		playerCache.cache.get(index).playheadState ===
			popcornPlayer.PlayheadState.Playing ||
		playerCache.cache.get(index).playheadState ===
			popcornPlayer.PlayheadState.Play
	) {
		playerCache.cache.get(index).pause();
	}
}

function destroyPlayer(player) {
	player.destroy(); // popcorn player destroy method
	player = null;
}

function addVolumeEventListeners(player, index) {
	if (activePlayer === index) {
		const players = playerCache.getCacheValues();
		const allOtherPlayers = players.filter((it) => it !== player);
		allOtherPlayers.forEach((otherPlayer) => {
			otherPlayer.setVolume(player.volume);
		});
	}
	storedVolume = player.volume;
}

function isIntersecting(el, bounds) {
	// if the the top AND bottom of the element is in the viewport
	// =====
	// scrollYAtPageLoad = we fetch getBoundingClientRect only once (on page load)
	// and if you return to the Trending via a back button if jumps to its last
	// known scroll position so we need that value
	// =====
	// intersectionThreshold = A single number between 0.0 and 1.0, specifying
	// a ratio of intersection area to total bounding box area for the observed
	// target. A value of 0.0 means that even a single visible pixel counts as
	// the target being visible. 1.0 means that the entire target element is visible.
	if (
		bounds.top +
			scrollYAtPageLoad +
			bounds.height * (1 - intersectionThreshold) >=
			window.scrollY &&
		bounds.bottom + scrollYAtPageLoad <=
			window.scrollY + wHeight + bounds.height * (1 - intersectionThreshold) &&
		!el.isIntersecting
	) {
		return true;
	}
	return false;
}

function isNotIntersecting(el, bounds) {
	// if the top OR the bottom is NOT in the viewport
	if (
		(bounds.top +
			scrollYAtPageLoad +
			bounds.height * (1 - intersectionThreshold) <
			window.scrollY ||
			bounds.bottom + scrollYAtPageLoad >
				window.scrollY +
					wHeight +
					bounds.height * (1 - intersectionThreshold)) &&
		el.isIntersecting
	) {
		return true;
	}
	return false;
}

/*
 *
 * TRACKING
 *
 */

function addTrackingEventListeners() {
	const items = document.querySelectorAll('[js-element~="cardTracker"]');

	items.forEach((item) => {
		item.addEventListener('click', () => {
			sendClickTracking(item);
		});
	});
}

function sendClickTracking(el) {
	const container = el.closest('[js-module~="cardData"]');
	const trackingData = container.dataset?.tracking
		? JSON.parse(container.dataset.tracking)
		: null;

	window.dataLayer.push({
		asset_id: trackingData?.assetId,
		coming_soon: trackingData?.comingSoon,
		event: 'product_click',
		list: feedListData,
		position: trackingData?.position,
		video: {
			id: trackingData?.videoId,
		},
	});
}

function sendImpressionTracking(el) {
	const container = el.closest('[js-module~="cardData"]');
	const trackingData = container.dataset?.tracking
		? JSON.parse(container.dataset.tracking)
		: null;

	window.dataLayer.push({
		asset_id: trackingData?.assetId,
		coming_soon: trackingData?.comingSoon ? true : false,
		event: 'product_impression',
		list: feedListData,
		position: trackingData?.position,
		video: {
			id: trackingData?.videoId,
		},
	});
}

function sendPlayIntentionTracking(el) {
	const container = el.closest('[js-module~="cardData"]');
	const trackingData = container.dataset?.tracking
		? JSON.parse(container.dataset.tracking)
		: null;

	window.dataLayer.push({
		asset_id: trackingData?.assetId,
		coming_soon: trackingData?.comingSoon ? true : false,
		event: 'trending_play_intention',
		list: feedListData,
		position: trackingData?.position,
		video: {
			id: trackingData?.videoId,
		},
	});
}

export default {
	init,
};
