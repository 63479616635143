export function getPageYOffset() {
	return window.pageYOffset || document.documentElement.scrollTop;
}

export function getViewportHeight() {
	return window.innerHeight;
}

export function getViewportWidth() {
	return window.innerWidth;
}
