function init() {
	const htmlElement = document.documentElement;

	htmlElement.classList.remove('no-js');
	htmlElement.classList.add('js');
}

export default {
	init,
};
