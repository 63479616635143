const ACTIVE_CLASS = 'is-active';

let activeAlert = null;
window.App = window.App || {};
window.App.alertQueue = false;

export default class Alert {
	constructor(id, icon, message, { onClose, onOpen } = {}, focussedElement) {
		// check if there's already a alert in the queue
		if (window.App?.alertQueue) {
			return;
		} else {
			this.resetInitialDOMState(); // so we can show the new alert immediately from another module
			window.App.alertQueue = true;
		}

		if (!id && !message) throw new Error('No alert message provided');

		this.id = id ? id : 'generalAlert';
		this.icon = icon;
		this.message = message;
		this.focussedElement = focussedElement;

		// Default callbacks, can be overwritten
		this.onClose = onClose;
		this.onOpen = onOpen;

		this.createAlert();
	}

	createAlert() {
		const alertHtml = document
			.querySelector('#alertTemplate')
			?.content.cloneNode(true);

		if (!alertHtml) return;

		// add a11y attributes
		const alertNode = alertHtml.querySelector('.alert');
		alertNode.setAttribute('aria-describedby', this.id);
		const alertBody = alertHtml.querySelector('.alert__body');
		alertBody.id = this.id;
		alertBody.textContent = this.message;

		// show the correct icon
		if (this.icon) {
			const alertIcon = alertHtml.querySelector('.alert__icon svg use');
			alertIcon.setAttribute('xlink:href', `${window.App.icons}#${this.icon}`);
		} else {
			alertHtml.querySelector('.alert__icon').remove();
		}

		activeAlert = { node: alertNode };

		this.showAlert();
	}

	showAlert(onOpen = this.onOpen) {
		window.requestAnimationFrame(() => {
			document.body.appendChild(activeAlert.node);

			window.requestAnimationFrame(() => {
				activeAlert.node.classList.add(ACTIVE_CLASS);
				activeAlert.node
					.querySelector('[js-element~="alertClose"]')
					.addEventListener('click', () => {
						this.hideAlert();
					});

				if (typeof onOpen === 'function') {
					onOpen();
				}
			});
		});
	}

	hideAlert(onClose = this.onClose) {
		if (activeAlert === null) {
			return;
		}

		activeAlert.node.addEventListener(
			'transitionend',
			this.resetInitialDOMState.bind(this)
		);

		activeAlert.node.classList.remove(ACTIVE_CLASS);
		if (typeof onClose === 'function') {
			onClose();
		}
	}

	resetInitialDOMState() {
		// double check, because the alert has multiple transitions running
		// (opacity, translateY) so it will fire twice
		if (activeAlert !== null) {
			activeAlert.node.remove();
			activeAlert = null; // this also removes any reference to this element so the eventlisteners are also removed (garbage collected)

			// needs to come after everything is reset to its default state otherwise it can't receive focus
			if (this.focussedElement) {
				this.focussedElement.focus();
			} else {
				document.body.focus();
			}

			window.App.alertQueue = false;
		}
	}
}
