function init() {
	document
		.querySelectorAll('[js-module~="avatarInput"]')
		.forEach(function (input) {
			input.addEventListener(
				'keyup',
				function () {
					setAvatarCharacter([...this.value][0]); // this way we keep emojis
				},
				false
			);
		});
}

function setAvatarCharacter(char) {
	document.querySelectorAll('.avatar__char').forEach((avatar) => {
		if (char && char.length) {
			avatar.textContent = char;
		}
	});
}

export default {
	init,
};
