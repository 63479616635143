import top10Video from '../../classes/top10-video/top10-video';

let elements;

async function init() {
	window.App = window.App || {};
	window.dataLayer = window.dataLayer || [];

	start();
}

function start() {
	elements = document.querySelectorAll('x-top10[data-show-cards="true"]');

	if (!elements.length) return;

	elements.forEach((element) => {
		new top10Video(element);
	});
}

export default {
	init,
};
