export default class DropDown {
	constructor(element, onChange) {
		if (!element) throw Error('No dropdown element provided');

		this.element = element;

		this.config = {
			valueSelector: '[js-element~="customSelectValue"]',
			optionSelector: '[js-element~="customSelectOption"]',
			wrapperSelector: '[js-element~="customSelectWrapper"]',
		};

		this.currentUrl = window.location.href;
		this.options = this.element.querySelectorAll(this.config.optionSelector);
		this.val = this.element.querySelector(this.config.valueSelector);
		this.wrapper = this.element.querySelector(this.config.wrapperSelector);
		this.onChange = onChange;
		this.focusedOption = null;

		this.init();
	}

	init = () => {
		// make it a11y
		this.element.setAttribute('tabindex', '0'); // is needed for mobile Safari otherwise :focus-within doesn't work
		this.val.setAttribute('aria-expanded', 'false');
		this.val.setAttribute('aria-controls', this.wrapper.id);
		this.val.setAttribute('aria-haspopup', 'listbox');
		this.val.setAttribute('role', 'combobox');
		this.wrapper.setAttribute('role', 'listbox');

		this.options.forEach((option) => {
			option.setAttribute('tabindex', '-1');
			option.setAttribute('role', 'option');
		});

		this.addEventListeners();
	};

	addEventListeners = () => {
		this.val.addEventListener('click', (e) => this.onSelectState(e), false);
		this.options.forEach((option) => {
			option.addEventListener('click', (e) => this.onSelectChange(e), false);
		});
	};

	onSelectState = (e) => {
		// close it
		if (this.val.getAttribute('aria-expanded') === 'true') {
			e.currentTarget.blur(); // to remove the focus (which opens the dropdown in CSS)
			this.closeDropdown();
		} else {
			// open it
			this.options.forEach((option) => {
				if (option.tagName === 'A') {
					option.removeAttribute('tabindex');
				} else {
					option.setAttribute('tabindex', '0'); // so it's focusable
				}
			});
			this.val.setAttribute('aria-expanded', 'true');
			this.wrapper.focus();
			window.addEventListener('click', this.globalClose, false);
			document.addEventListener('keydown', this.keydownHandler);
		}
	};

	onSelectChange = (e, onChange = this.onChange) => {
		const newUrl = e.currentTarget.getAttribute('href');

		if (this.currentUrl === newUrl) {
			return;
		}

		this.options.forEach((option) => {
			option.setAttribute('aria-selected', 'false');
		});
		this.val.textContent = e.currentTarget.textContent;
		e.currentTarget.setAttribute('aria-selected', 'true');
		e.currentTarget.blur(); // close the dropdown

		this.currentUrl = newUrl;

		if (typeof onChange === 'function') {
			onChange(e.currentTarget);
		}
	};

	globalClose = (e) => {
		if (e.target !== this.val) {
			this.closeDropdown();
		}
	};

	keydownHandler = (e) => {
		if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
			this.closeDropdown();
		}

		if (e.key === 'ArrowDown' || e.keyCode === 40) {
			// disabling the scrolling of the browser when using arrow keys
			e.preventDefault();
			if (this.focusedOption === null) {
				this.focusedOption = 0;
			} else {
				this.focusedOption++;
			}
			if (this.focusedOption < 0) this.focusedOption = 0;
			if (this.focusedOption >= this.options.length)
				this.focusedOption = this.options.length - 1;
			if (this.options[this.focusedOption].tagName === 'A') {
				this.options[this.focusedOption].focus();
			} else {
				this.options[this.focusedOption].querySelector('input').focus();
			}
		}

		if (e.key === 'ArrowUp' || e.keyCode === 38) {
			// disabling the scrolling of the browser when using arrow keys
			e.preventDefault();
			if (this.focusedOption === null) return;
			this.focusedOption--;
			if (this.focusedOption < 0) this.focusedOption = 0;
			if (this.focusedOption >= this.options.length)
				this.focusedOption = this.options.length - 1;
			if (this.options[this.focusedOption].tagName === 'A') {
				this.options[this.focusedOption].focus();
			} else {
				this.options[this.focusedOption].querySelector('input').focus();
			}
		}
	};

	closeDropdown = () => {
		this.val.setAttribute('aria-expanded', 'false');
		this.options.forEach((option) => {
			option.setAttribute('tabindex', '-1');
		});
		this.val.blur();
		this.focusedOption = null;
		document.removeEventListener('keydown', this.keydownHandler);
		window.removeEventListener('click', this.globalClose, false);
	};
}
