import cookiez from '../../utils/cookies/cookies';
/*
// Deleting the redundant cookiebanner-cookie on Streamz
// It's replaced with Sourcepoint
*/
const COOKIE_BANNER_COOKIE = 'lfvp-cookiebanner';

function init() {
	const cooky = cookiez.get(COOKIE_BANNER_COOKIE);

	if (cooky && cooky.length > 0) {
		cookiez.remove(COOKIE_BANNER_COOKIE);
	}
}

export default {
	init,
};
