function set(cname, cvalue, exdays, path) {
	if (!cvalue) return;
	const d = new Date();
	let expires = null;

	if (exdays > 0) {
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = 'expires=' + d.toUTCString();

		if (path) {
			document.cookie =
				cname + '=' + cvalue + '; ' + expires + '; path=' + path;
		} else {
			document.cookie = cname + '=' + cvalue + '; ' + expires;
		}
	} else {
		document.cookie = cname + '=' + cvalue;
	}
}

function get(cname) {
	const name = cname + '=';
	const ca = document.cookie.split(';');
	let c = null;

	for (let i = 0; i < ca.length; i++) {
		c = ca[i];

		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}

		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
}

function remove(cname) {
	document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
const cookieFunctions = {
	set,
	get,
	remove,
};

export default cookieFunctions;
