export const scrollIntoView = (parent, child) => {
	const nodeBottom = parent.getBoundingClientRect().height;

	const itemTop = child.offsetTop - parent.scrollTop;
	const itemBottom = itemTop + child.getBoundingClientRect().height;

	if (nodeBottom < itemBottom) {
		parent.scrollTop = itemBottom - nodeBottom;
	}
};
