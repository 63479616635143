import Anchors from '../../classes/anchors/anchors';
import Tabs from '../../classes/tabs/tabs';
import { debounce } from '../../utils/throttle/debounce';
import tracking from '../tracking/tracking.logic';

const maxBreakpoint = 1024;
let tabsEl,
	tabs,
	anchors,
	anchorsEl,
	tabsInitialized,
	anchorsInitialized,
	observer;

function init() {
	tabs = [];
	anchors = [];
	tabsInitialized = false;
	anchorsInitialized = false;
	tabsEl = document.querySelectorAll('[js-module~="detailTabs"]');
	anchorsEl = document.querySelectorAll('[js-module~="anchors"]');

	if (tabsEl.length) {
		if (window.innerWidth < maxBreakpoint) {
			startTabs();
		} else {
			startAnchors();
		}

		window.addEventListener('resize', debounce(checkViewPortWidth, 100), {
			passive: true,
		});

		const links = document.querySelectorAll('[js-element~="anchorsLink"]');
		links.forEach((link) => {
			link.addEventListener(
				'click',
				() => {
					window.dataLayer.push({
						event: 'select_detail',
						detail_menu_item: link.textContent,
					});
				},
				{ passive: true }
			);
		});

		const seasonLinks = document.querySelectorAll(
			'[js-module~="seasonPicker"]'
		);
		seasonLinks.forEach((link) => {
			link.addEventListener(
				'click',
				() => {
					if (anchorsInitialized) {
						anchors.forEach((anchor) => {
							anchor.removeForcedLink();
						});
					}
				},
				{ passive: true }
			);
		});
	}

	const trackedTabPanels = document.querySelectorAll(
		'[js-element~="tabPanelImpression"]'
	);

	if (trackedTabPanels.length) {
		createTrackingObserver(trackedTabPanels);
	}
}

// the impression tracking of "Meer zoals dit" / "Anderen bekeken ook"
function createTrackingObserver(elements) {
	if ('IntersectionObserver' in window) {
		observer = new IntersectionObserver(onIntersection, {
			threshold: 0.1,
		});

		elements.forEach((el) => {
			observer.observe(el);
		});
	}
}

function onIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			tracking.init(entry.target);
			// once observed and tracked, now stop observing that entry
			observer.unobserve(entry.target);
		}
	});
}

function checkViewPortWidth() {
	if (window.innerWidth >= maxBreakpoint) {
		if (tabsInitialized) {
			if (tabs.length) {
				tabs.forEach((tab) => {
					tab.destroy();
				});
			}

			tabsInitialized = false;
		}

		destroyAnchors();
		startAnchors();
	} else {
		if (!tabsInitialized) {
			startTabs();
		}

		destroyAnchors();
	}
}

function startTabs() {
	for (let i = 0; i < tabsEl.length; i++) {
		tabs.push(new Tabs(tabsEl[i]));
	}

	tabsInitialized = true;
}

function startAnchors() {
	for (let i = 0; i < anchorsEl.length; i++) {
		anchors.push(new Anchors(anchorsEl[i]));
	}

	anchorsInitialized = true;
}

function destroyAnchors() {
	if (anchorsInitialized) {
		anchors.forEach((anchor) => {
			anchor.destroy();
		});

		anchorsInitialized = false;
	}
}

export default {
	init,
};
