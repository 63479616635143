/* globals popcornPlayer */
import { isMobile } from '../../utils/breakpoints/breakpoints';
import browsersniffing from '../../utils/browsersniffing/browsersniffing';
import { debounce } from '../../utils/throttle/debounce';
import {
	getTrailerVolumeSetting,
	setButtonMutedState,
	setButtonUnMutedState,
	toggleVolumeButtonState,
} from '../trailers/trailers.volume';

let observer;
let trailerIsEnded;
let contextData;
let loadingTimeout;

async function init() {
	window.App = window.App || {};
	window.addEventListener('resize', debounce(resizingHandler, 100));

	// no trailers in banners on mobile devices or breakpoint < 768px
	if (browsersniffing.detectMobileOS() || isMobile()) {
		document.documentElement.classList.add('is-mobile');
		return;
	}

	trailerIsEnded = false;

	await start();
}

async function start() {
	const elements = document.querySelectorAll('[js-module~="bannerTrailer"]');

	if (!elements.length) {
		return;
	}

	const promises = [
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.Heartbeat),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.GTM),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.CIM),
		popcornPlayer.loadPlugin(popcornPlayer.PluginName.Mux),
		popcornPlayer.loadScript(popcornPlayer.ScriptName.GemiusPlayer),
	];

	await Promise.all(promises);

	createObserver(elements);

	// destroy all the players and elements on `page leave` so we don't get weird
	// behaviour when returning to this page via the back button (updated DOM in the page cache)
	window.addEventListener('beforeunload', destroyTopBannerTrailer);
}

function createObserver(elements) {
	if ('IntersectionObserver' in window) {
		observer = new IntersectionObserver(onIntersection, {
			threshold: 0.5,
		});

		elements.forEach((el) => {
			observer.observe(el);
		});
	}
}

function onIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			playTopBannerTrailer(entry.target);
		} else {
			// not intersecting
			const obj = window.App.activeTrailers.find(
				({ element }) => element === entry.target
			);

			if (!obj) return;

			if (obj.instance && obj.state === 'loaded' && !trailerIsEnded)
				obj.instance.pause();

			if (obj.state !== 'loaded') {
				obj.instance.destroy();
				obj.instance = undefined;
				obj.element = undefined;
				obj.state = undefined;
			}
		}
	});
}

function playTopBannerTrailer(el) {
	if (!el || trailerIsEnded) return;

	// returns undefined if nothing is found
	if (window.App.activeTrailers.find(({ element }) => element === el)) {
		const obj = window.App.activeTrailers.find(({ element }) => element === el);
		obj.instance.setVolume(getTrailerVolumeSetting());
		obj.instance.play();
		return;
	}

	const aspectRatioSupported = CSS.supports('(aspect-ratio: 16 / 9)');
	if (!aspectRatioSupported) {
		setWidth(el.parentElement);
	}

	window.App.activeTrailers.push({
		element: el,
		instance: undefined,
		state: undefined,
	});

	loadPlayer(el);
}

function setWidth(el) {
	if (el) {
		const bounds = el.getBoundingClientRect();
		el.style.width = bounds.height * (16 / 9) + 'px';
	}
}

function loadPlayer(container) {
	const assetId = container.dataset.id;
	contextData = window.playerConfig;
	const player = new popcornPlayer.Player(container, {
		device: {
			name: 'browser',
		},
		containerAspectRatio: 16 / 9,
		controls: {
			language: contextData.uiLanguage,
			keyboardNavigation: 'focusOnly',
		},
	});

	const controlsParams = {
		posterImageUrl: window.App.players[assetId].poster,
	};

	const obj = window.App.activeTrailers.find(
		({ element }) => element === container
	);

	obj.instance = player;

	const parent = container.parentElement;
	const wrapper = container.closest('top-banner');
	const replayButton = wrapper.querySelector(
		'[js-element~="bannerReplayButton"]'
	);
	const loaderButton = wrapper.querySelector(
		'[js-element~="bannerLoaderButton"]'
	);
	const volumeButton = wrapper.querySelector(
		'[js-element~="bannerVolumeButton"]'
	);
	const fullscreenButton = wrapper.querySelector(
		'[js-element~="bannerFullscreenButton"]'
	);

	if (!getTrailerVolumeSetting()) {
		setButtonMutedState(volumeButton);
	} else {
		setButtonUnMutedState(volumeButton);
	}

	player.addEventListener(popcornPlayer.Events.LoadStatusChange, (event) => {
		if (event.to === popcornPlayer.LoadStatus.Preparing) {
			wrapper.classList.add('is-preparing');
		}

		if (event.to === popcornPlayer.LoadStatus.Loading) {
			wrapper.classList.remove('is-preparing');
			wrapper.classList.add('is-loading');

			player.setVolume(getTrailerVolumeSetting());
			// if autoplay is not supported we provide an alternative way
			// to start the trailer
			loadingTimeout = setTimeout(() => {
				loaderButton.classList.add('is-hidden');
				wrapper.classList.add('is-autoplay-blocked');
			}, 3000);
		}

		if (event.to === popcornPlayer.LoadStatus.Loaded) {
			obj.state = 'loaded';
			wrapper.classList.add('is-ready');
			wrapper.classList.remove('is-loading');

			const tracks = player.textTracks;
			const defaultTrack = tracks.find(
				(track) =>
					track.language === window.playerConfig.uiLanguage && !track.variant
			);
			if (defaultTrack) player.selectTextTrack(defaultTrack.id);
		}
	});

	player.addEventListener(popcornPlayer.Events.PlayheadStateChange, (event) => {
		if (event.to === popcornPlayer.PlayheadState.Playing) {
			wrapper.classList.add('is-playing');
			wrapper.classList.remove('is-ended');
			wrapper.classList.remove('is-ready');
			wrapper.classList.remove('is-loading');
			wrapper.classList.remove('is-autoplay-blocked');
			parent.setAttribute('aria-hidden', 'false');
			replayButton.classList.add('is-hidden');
			clearTimeout(loadingTimeout);
			trailerIsEnded = false; // in case you clicked "replay"
		}

		if (event.to === popcornPlayer.PlayheadState.Ended) {
			wrapper.classList.remove('is-playing');
			wrapper.classList.add('is-ended');
			parent.setAttribute('aria-hidden', 'true');
			replayButton.classList.remove('is-hidden');
			// mark it as a completed trailer
			trailerIsEnded = true;
		}
	});

	player.addEventListener(popcornPlayer.Events.FullscreenChange, () => {
		wrapper.classList.toggle('is-fullscreen', player.isFullscreen);
	});

	volumeButton.addEventListener('click', function (e) {
		e.preventDefault();
		player.setVolume(player.volume === 0 ? 1 : 0);
		toggleVolumeButtonState();
	});

	replayButton.addEventListener('click', (e) => {
		e.preventDefault();
		// temp fix for Firefox - sometimes replay doens't work in that browser
		// unless your force reload the page
		if (browsersniffing.detectBrowserName().includes('Firefox')) {
			window.location.reload();
		}
		player.play();
	});

	fullscreenButton.addEventListener('click', () => {
		player.toggleFullscreen();
	});

	player.loadFromButter(
		{
			// butterParams
			apiKey: contextData.apiKey,
			id: assetId,
			authToken: contextData.token,
			env: contextData.environment,
			unstable_userIdForAdvertising: false,
			userId: contextData.accountId,
			zone: contextData.zone,
		},
		{
			// playerParams
			allowMutedAutoPlay: true, // for Safari
			autoPlay: true,
			controls: controlsParams,
			tracking: {
				gtm: {
					dataLayerName: 'dataLayer',
				},
			},
			volume: getTrailerVolumeSetting(),
		}
	);
}

function resizingHandler() {
	if (browsersniffing.detectMobileOS() || isMobile()) {
		document.documentElement.classList.add('is-mobile');
		destroyTopBannerTrailer();
		return;
	}

	document.documentElement.classList.remove('is-mobile');
}

function destroyTopBannerTrailer() {
	let obj = window.App?.activeTrailers?.find(({ element }) =>
		element.classList.contains('top-banner__video-container')
	);

	if (!obj) return;

	obj.instance.destroy();

	trailerIsEnded = false;
	const topBannerVideo = document.querySelector('.top-banner__video-container');
	const topBannerVideoWrapper = topBannerVideo.closest('top-banner');

	topBannerVideoWrapper.classList.remove('is-ready', 'is-playing', 'is-ended');
	topBannerVideo.parentElement.setAttribute('aria-hidden', 'true');

	observer.disconnect();
	window.App.activeTrailers = [];
}

export default {
	init,
};
