import shortformFeed from './shortform.feed';
import shortformFeedStickyHeader from './shortform.feed.sticky.header';

function init() {
	shortformFeed.init();
	shortformFeedStickyHeader.init();
}

export default {
	init,
};
