let title = null;

function init() {
	const popovers = document.querySelectorAll('[js-module~="popover"]');

	if (!popovers.length) return;

	popovers.forEach((popover) => {
		if (popover.title) {
			popover.addEventListener('mouseenter', showPopover, { passive: true });
		}
	});
}

function showPopover() {
	const popover = document.createElement('div');
	popover.classList.add('popover');
	popover.textContent = this.title;
	title = this.title;
	this.removeAttribute('title');

	this.append(popover);

	this.addEventListener('mouseleave', destroyPopover, { passive: true });
}

function destroyPopover() {
	document.querySelector('.popover').remove();
	this.title = title;
}

export default {
	init,
};
