import progress from '../progress/progress';

let updateInterval, retryCounter;

function init() {
	retryCounter = 0;
	const livePage = document.querySelector('[js-module~="live"]');
	const liveSwimlane = document.querySelector('[swimlane-type="live"]');

	if (livePage) {
		updateInterval = window.setInterval(fetchNewEpgList, 3600 * 1000);
	}

	if (liveSwimlane) {
		progress.init();
	}
}

function updateChannel(id) {
	if (
		!window.App?.liveEpg ||
		!Object.prototype.hasOwnProperty.call(window.App?.liveEpg, id)
	) {
		return;
	}

	const broadcasts = window.App?.liveEpg[id];
	if (broadcasts.length) {
		broadcasts.shift();
		const activeBroadcast = broadcasts[0];
		const channels = document.querySelectorAll(
			'[js-module~="liveEpg"][data-channel-id="' + id + '"]'
		);

		channels.forEach((channel) => {
			channel
				.querySelectorAll('[js-element~="channelTitle"]')
				.forEach((title) => {
					title.textContent = activeBroadcast.name;
				});

			if (channel.querySelector('[js-element~="channelImg"]') !== null) {
				channel.querySelector('[js-element~="channelImg"]').src =
					activeBroadcast.imageUrl;
			}

			channel
				.querySelectorAll('[js-element~="startTime"]')
				.forEach((startTime) => {
					startTime.textContent = activeBroadcast.startsAtPretty;
				});

			channel.querySelectorAll('[js-element~="endTime"]').forEach((endTime) => {
				endTime.textContent = activeBroadcast.endsAtPretty;
			});

			channel
				.querySelectorAll('[js-module~="progress"]')
				.forEach((progress) => {
					progress.setAttribute(
						'data-starttime',
						activeBroadcast.startsAtEpochSeconds
					);
					progress.setAttribute(
						'data-endtime',
						activeBroadcast.endsAtEpochSeconds
					);
				});
		});

		progress.update();
	}
}

async function fetchNewEpgList() {
	let success = false;
	let abort = false;

	while (!success && !abort) {
		try {
			const response = await fetch(window.App?.live.updateUrl, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			});

			if (!response.ok) {
				retryCounter++;

				if (retryCounter > 2) {
					retryCounter = 0;
					abort = true;
					window.clearInterval(updateInterval);
					init();
					return;
				}
			} else {
				const content = await response.json();
				window.App.liveEpg = content;
				success = true;
				return;
			}
		} catch {
			throw new Error('Something went wrong with the EPG');
		}
	}
}

export default {
	init,
	updateChannel,
};
