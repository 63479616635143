import Modal from '../../classes/modal/modal';
import Alert from '../../classes/alert/alert';
import {
	initiateMktOverlayTracking,
	sendMktOverlayOnClose,
} from '../marketing/marketing';

function init() {
	document
		.querySelectorAll('[js-module~="blockingOverlays"]')
		.forEach((trigger) => {
			trigger.addEventListener('click', (e) => {
				// do not select the (geo-) blocked teasers if the teaser.edit mode is active
				if (e.currentTarget.hasAttribute('aria-hidden')) return;

				const overlayId = e.currentTarget.getAttribute('data-overlay-id');
				/*
				 * The overlayId is used to find the correct overlay template in the DOM.
				 * Because the same template can occur more than once in the DOM (something with Ajax/Fetch), we use
				 * the data-template-id attribute (instead of id, which has to be unique)
				 * to identify the correct template.
				 */
				const overlay = document.querySelector(
					`[data-template-id="${overlayId}"]`
				);
				const html = overlay?.content.cloneNode(true);

				if (!html) return;

				if (overlay.dataset.overlayType === 'popup') {
					const trackingData = JSON.parse(overlay.dataset.tracking);
					let callbacks = null;
					if (trackingData) {
						callbacks = {
							onOpen: initiateMktOverlayTracking.bind(null, trackingData),
							onClose: sendMktOverlayOnClose.bind(null, trackingData),
						};
					}
					new Modal(
						overlayId,
						{
							message: html,
						},
						callbacks
					);
				} else {
					new Alert(
						overlayId,
						'globe',
						html.textContent.trim(),
						{},
						e.currentTarget
					);
				}

				e.preventDefault();
			});
		});
}

export default {
	init,
};
