const controller = new AbortController();
const signal = controller.signal;

export const putPlayerPosition = async (player, position, contextData) => {
	await fetch(contextData.updatePlayerPositionUrl, {
		method: 'PUT',
		signal: signal,
		headers: {
			'Content-Type': 'application/json',
			'x-csrf-token': window.App.csrfToken,
		},
		body: JSON.stringify({
			position,
			duration: parseInt(player.contentDuration, 10),
			marker: player.markers?.type,
		}),
		keepalive: true, // allow the request to outlive the page
	}).catch(() => {
		// do nothing
	});
};

export const abortPutPlayerPosition = () => {
	controller.abort();
};
