/*
   Sets a delay on a function
   It's better than setTimeout apparently
*/
export const throttle = (delay, fn) => {
	let lastCall = 0;

	return function () {
		const now = new Date().getTime();
		if (now - lastCall < delay) {
			return;
		}

		lastCall = now;

		return fn();
	};
};
