function init() {
	if ('serviceWorker' in navigator) {
		// unregister  serviceworkers, Streamz no longer wants push notifs on web
		if (window.location.host.includes('streamz.be')) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					if (
						registration.active.scriptURL.includes('/OneSignalSDKWorker.js')
					) {
						registration.unregister();
					}
				}
			});
			return;
		}

		// unregister old serviceworkers from rtlplay (Bedrock)
		if (window.location.host.includes('rtlplay.be')) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					if (
						registration.active.scriptURL.includes(
							'/service-worker-5.88.5.bundle.js'
						)
					) {
						registration.unregister();
					}
				}
			});
		}

		// PushEngage
		// it's advised to use only 1 sw per domain
		if (window.location.host.includes('vtmgo.be')) {
			navigator.serviceWorker.register('/service-worker.js?v=2');
			return;
		}
		return;
	}
}

export default {
	init,
};
