import {
	isNewSession,
	SESSION_COOKIE_NAME,
	SESSION_COOKIE_VALUE,
} from '../../utils/session/session';

const init = () => {
	if (isNewSession()) {
		window.sessionStorage.setItem(SESSION_COOKIE_NAME, SESSION_COOKIE_VALUE);
	}
};

export default {
	init,
};
