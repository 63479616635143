function init() {
	window.dataLayer = window.dataLayer || [];

	const profileEvents = document.querySelectorAll(
		'[js-module~="profileTracking"]'
	);

	profileEvents.forEach((link) => {
		link.addEventListener('click', sendProfileInfoToTracking, {
			passive: true,
		});
	});
}

function sendProfileInfoToTracking() {
	const eventType = this.dataset.profileEventType;
	const profileId =
		eventType === 'profile_create' ? null : this.dataset.profileId;

	window.dataLayer.push({
		event: eventType,
		selected_profile_id: profileId,
	});
}

export default {
	init,
};
