import tracking from './tracking.logic';

let observer;

function init() {
	const trackedElements = document.querySelectorAll('[lfvp-tracking]');

	if (trackedElements.length) {
		createObserver(trackedElements);
	}
}

function createObserver(elements) {
	if (
		'IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'isIntersecting' in window.IntersectionObserverEntry.prototype
	) {
		observer = new IntersectionObserver(onIntersection, {
			threshold: 0.5,
		});

		elements.forEach((el) => {
			observer.observe(el);
		});
	}
}

function onIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			// start tracking
			tracking.init(entry.target);
			// once observed and tracked, now stop observing that entry
			observer.unobserve(entry.target);
		}
	});
}

export default {
	init,
};
