// DMOI is a DPG Media proprietary userID that represents the user's id across
// all products from DPG Media
// -> Not applicable to Streamz and RTL Play

let abortTimeout;

export async function fetchDMOI(cxenseUuid) {
	const DMOI_FETCH_TIMEOUT = 3000;
	const PROFILE_SERVICE_URL =
		'https://profile-public-api.data.dpgmedia.cloud/dmoi';
	const apiKey = window.App?.dmoiConfig?.apiKey;
	// 'null' is a string, it comes from a properties file
	if (apiKey === 'null' || !apiKey) return null;

	try {
		const abortController = new AbortController();
		abortTimeout = setTimeout(() => {
			abortController.abort('Fetch DMOI Timed out');
		}, DMOI_FETCH_TIMEOUT);
		const response = await fetch(PROFILE_SERVICE_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
			},

			body: JSON.stringify({
				gigya_id: window.playerConfig.accountId,
				cxense_id: cxenseUuid,
			}),
			signal: abortController.signal,
		});

		clearTimeout(abortTimeout);

		return (await response.json()).dmoi;
	} catch (error) {
		console.error('[ADVERT] Could not fetch DMOI', error);
		return null;
	}
}
