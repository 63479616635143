import cookiez from '../../utils/cookies/cookies';

function init() {
	const products = ['vtmgo', 'streamz'];

	// remove all obsolete cookies
	products.forEach((prod) => {
		cookiez.remove(prod + '_userSession');
		cookiez.remove(prod + '_main-location');
		cookiez.remove(prod + '_origin-location');
	});
	// replaced by real sessionStorage
	cookiez.remove('lfvp_userSession');
}

export default {
	init,
};
