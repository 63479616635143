import DropDown from '../../classes/dropDown/dropDown';
import Tabs from '../../classes/tabs/tabs';
import swimlane from '../swimlane/swimlane';
import { SWIMLANE_POSITION_STORAGE_KEY } from '../../classes/swimlane/swimlane';
import Alert from '../../classes/alert/alert';

window.App = window.App || {};

function init() {
	document.querySelectorAll('[js-module~="tvGuide"]').forEach((element) => {
		new Tabs(element, null, onChangeCallback);
		new DropDown(
			element.querySelector('[js-element~="tvGuideCustomSelect"]'),
			onChangeCallback
		);
		tvGuideTracking(element);
	});
	tvGuideOverlay();
}

async function onChangeCallback(el) {
	// get the input that the <label> controls
	const control = el.control;
	const moduleEl = el.closest('[js-module~="tvGuide"]');

	const appendToContainer = moduleEl.querySelector('x-swimlane__scroller');
	// Remove old tvguide from swimlane
	appendToContainer.innerHTML = '';
	// Remove the shift of the swimlane.
	// Otherwise the skeleton might not be visible (out of viewport)
	appendToContainer.removeAttribute('style');

	// show a skeleton as loading indicator
	const skeletonTemplate = document.getElementById('tvGuideSkeletonTemplate');
	const skeletonHtml = skeletonTemplate?.content.cloneNode(true);
	appendToContainer.appendChild(skeletonHtml);

	// set the input to checked
	control.checked = true;

	fetchTvGuide(control.form);
	// send product impression to tracking
	sendChangeCallbackTracking(control);
}

async function fetchTvGuide(form) {
	let channelData = null;
	let dayData = null;
	form.querySelectorAll('[name="channel"]').forEach((el) => {
		if (el.checked) {
			channelData = el.value;
		}
	});
	form.querySelectorAll('[name="day"]').forEach((el) => {
		if (el.checked) {
			dayData = el.value;
		}
	});

	const response = await fetch(
		`${form.action}?day=${dayData}&channel=${channelData}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'text/xml',
				'X-Requested-With': 'XMLHttpRequest',
			},
		}
	);

	// replace old tv guide with new tv guide
	const content = await response.text();
	const swimlaneElement = document.getElementById(form.dataset.id);
	let originalRow = swimlaneElement.closest('x-row');
	originalRow.insertAdjacentHTML('afterend', content);
	originalRow.remove();
	originalRow = null; // garbage collection

	const newTvGuideSwimlane = document.getElementById(form.dataset.id);
	// don't track the impression of the new swimlane, it gives double product impressions
	newTvGuideSwimlane.dataset.impressionTracking = 'false';

	if (sessionStorage.getItem(SWIMLANE_POSITION_STORAGE_KEY)) {
		if (
			JSON.parse(sessionStorage.getItem(SWIMLANE_POSITION_STORAGE_KEY)).id ===
			form.dataset.id
		) {
			sessionStorage.removeItem(SWIMLANE_POSITION_STORAGE_KEY);
		}
	}
	// create new tv guide swimlane
	swimlane.init(
		document.querySelectorAll(`x-swimlane[data-id="${form.dataset.id}"]`)
	);
	// attach eventlisteners to new tv guide
	new Tabs(newTvGuideSwimlane, null, onChangeCallback);
	new DropDown(
		newTvGuideSwimlane.querySelector('[js-element~="tvGuideCustomSelect"]'),
		onChangeCallback
	);
	// attach tracking to new tv guide
	tvGuideTracking(newTvGuideSwimlane);
	// attach overlay eventlisteners
	tvGuideOverlay();
}

function tvGuideTracking(element) {
	const listData = generateListData(element.dataset.tracking);

	element
		.querySelectorAll('[js-element~="tvGuideTracking"]')
		.forEach((item) => {
			item.addEventListener('click', () => {
				reportProductClick(item, listData);
			});
		});

	element
		.querySelectorAll('[js-element~="tvGuideFullGuideTracking"]')
		.forEach((link) => {
			link.addEventListener('click', () => {
				window.dataLayer.push({
					event: 'tvguide_full_guide',
				});
			});
		});
}

function reportProductClick(item, listData) {
	const itemData = generateItemData(item.dataset.tracking);
	listData.asset_position = itemData.position ?? null;

	const ancestorOfItem = item.closest('x-swimlane');

	const clickData = {
		asset_id: itemData.assetId,
		channel_label: ancestorOfItem.querySelector('[name="channel"]:checked')
			.dataset.label,
		date_label: ancestorOfItem.querySelector('[name="day"]:checked').dataset
			.label,
		event: 'product_click',
		list: listData,
	};

	window.dataLayer.push(clickData);
}

function sendChangeCallbackTracking(element) {
	window.dataLayer.push({
		channel_label:
			element.name === 'channel'
				? element.dataset.label
				: element.form.querySelector('[name="channel"]:checked').dataset.label,
		date_label:
			element.name === 'day'
				? element.dataset.label
				: element.form.querySelector('[name="day"]:checked').dataset.label,
		event: 'product_impression',
		list: generateListData(element.closest('x-swimlane').dataset.tracking),
	});
}

function sendTvGuideAlertTracking(title) {
	window.dataLayer.push({
		broadcast_asset_title: title,
		event: 'tvguide_only_live_toast',
	});
}

function generateItemData(trackingData) {
	return JSON.parse(trackingData);
}

function tvGuideOverlay() {
	document.querySelectorAll('[js-element~="tvGuideOverlay"]').forEach((el) => {
		el.addEventListener('click', (e) => {
			const overlayId = el.dataset.overlayId;
			const overlay = document.querySelector(
				`[data-template-id="${overlayId}"]`
			);
			const html = overlay?.content.cloneNode(true);

			if (!html) return;

			const data = JSON.parse(el.dataset.tracking);
			new Alert(
				overlayId,
				null,
				html.textContent.trim(),
				{
					onOpen: sendTvGuideAlertTracking.bind(null, data.title),
				},
				el
			);
			e.preventDefault();
			// small trick to show the alert multiple times
			window.App.alertQueue = false;
		});
	});
}

function generateListData(data) {
	const trackingData = JSON.parse(data);

	const listData = {
		id: trackingData.id,
		name: trackingData.name ?? null,
		position: trackingData.position,
	};

	return listData;
}

export default {
	init,
	onChangeCallback,
};
