import profilesCreate from './profiles.create';
import profilesDelete from './profiles.delete';
import profilesTracking from './profiles.tracking';

function init() {
	profilesCreate.init();
	profilesDelete.init();
	profilesTracking.init();
}

export default {
	init,
};
