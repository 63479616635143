/*
 * Using the native <dialog> for browsers that support it and
 * fallback to <div class="modal" role="dialog"> for older
 * browsers
 * v3.2.0
 * Inspired by https://web.dev/building-a-dialog-component/
 */

window.App = window.App || {};
window.App.modalQueue = false;

const BODY_MODAL_CLASS = 'has-modal';
const MODAL_OPEN_CLASS = 'is-open';
const prefersReducedMotion =
	window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
	window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
// custom events to be added to <dialog>
const dialogClosingEvent = new Event('dialogClosing');
const dialogClosedEvent = new Event('dialogClosed');
const dialogOpeningEvent = new Event('dialogOpening');
const dialogOpenedEvent = new Event('dialogOpened');

export default class Modal {
	constructor(type, options, { onClose, onOpen } = {}, modifiers) {
		// check if there's already a modal in the queue
		if (window.App?.modalQueue) {
			return;
		} else {
			window.App.modalQueue = true;
		}

		this.closeButton = true;

		if (options && typeof options === 'object') {
			this.message = options.message ?? null;
			this.closeButton = options.closeButton ?? this.closeButton;
		}

		if (!this.message) throw Error('No content provided for the modal');
		this.id = type ? type : 'GeneralModal';
		this.dialog = null;
		this.dialogBackdrop = null;

		this.isOpen = false;
		this.modifiers = modifiers; // array of additional class names
		this.supportsNativeDialogElement = false;

		// Default callbacks, can be overwritten
		this.onClose = onClose;
		this.onOpen = onOpen;

		this.checkDialogElementCompatibility();
		this.createDialog();
	}

	close() {
		this.closeDialog();
	}

	open() {
		this.showDialog();
	}

	checkDialogElementCompatibility() {
		if (typeof HTMLDialogElement === 'function') {
			this.supportsNativeDialogElement = true;
		}
	}

	createDialog() {
		const dialog = this.supportsNativeDialogElement
			? document.createElement('dialog')
			: document.createElement('div');
		dialog.id = this.id;
		dialog.setAttribute('role', 'dialog');
		dialog.setAttribute('inert', '');
		dialog.setAttribute('aria-labelledby', `${this.id}Title`);
		dialog.setAttribute('aria-live', 'assertive');
		dialog.setAttribute('aria-modal', 'true');
		dialog.setAttribute('data-nosnippet', 'true');
		dialog.setAttribute('aria-hidden', 'true');

		dialog.classList.add('modal', 'is-loading');

		if (this.modifiers && Array.isArray(this.modifiers)) {
			this.modifiers.forEach((className) => {
				dialog.classList.add(className);
			});
		}

		if (this.closeButton) {
			const closeButton = document.createElement('button');
			closeButton.classList.add(
				'modal__close',
				'btn',
				'btn--secondary',
				'btn--icon'
			);
			closeButton.setAttribute('type', 'button');
			closeButton.setAttribute('js-element', 'modalClose');
			closeButton.setAttribute('aria-label', window.i18n?.close);
			closeButton.innerHTML = `<span class="btn__icon"><svg class="icon" aria-hidden="true"><use xlink:href="${window.App.icons}#close"></use></svg></span>`;
			dialog.appendChild(closeButton);
		}

		if (this.message) {
			// it's already HTML (an Object)
			if (typeof this.message === 'object') {
				dialog.appendChild(this.message);
			} else {
				throw new Error('No Modal message as HTML provided');
			}

			// if the message (HTML) contains a title add an id to it
			const messageTitle = dialog.querySelector('.modal__title');
			if (messageTitle) {
				messageTitle.id = messageTitle.id ? messageTitle.id : `${this.id}Title`;
			}
		}

		if (!this.supportsNativeDialogElement) this.createCustomBackdrop();
		document.body.appendChild(dialog);
		this.dialog = dialog;
		this.addDialogCloseEventListeners();
		this.showDialog();
	}

	createCustomBackdrop() {
		// it's possible that there already is a backdrop in the DOM from a
		// previous dialog
		const backdrop = document.querySelector('.modal__backdrop');
		if (backdrop) {
			this.dialogBackdrop = backdrop;
		} else {
			const customBackdrop = document.createElement('div');
			customBackdrop.classList.add('modal__backdrop');
			customBackdrop.setAttribute('aria-hidden', 'true');
			document.body.appendChild(customBackdrop);
			this.dialogBackdrop = customBackdrop;
		}
	}

	showDialog(onOpen = this.onOpen) {
		this.dialog.dispatchEvent(dialogOpeningEvent);
		document.body.classList.add(BODY_MODAL_CLASS);
		this.dialog.classList.remove('is-loading');
		this.dialog.removeAttribute('aria-hidden');

		if (!prefersReducedMotion) {
			this.dialog.addEventListener(
				'animationend',
				() => {
					this.dialog.dispatchEvent(dialogOpenedEvent);
				},
				{ once: true }
			);
		}

		if (this.supportsNativeDialogElement) {
			this.dialog.showModal(); // native <dialog> show method
		} else {
			this.dialog.classList.add(MODAL_OPEN_CLASS);
			[...document.body.children].forEach((child) => {
				if (
					!child.classList.contains('modal') &&
					!child.classList.contains('modal__backdrop') &&
					child.tagName !== 'SCRIPT' &&
					child.tagName !== 'TEMPLATE'
				) {
					child.setAttribute('aria-hidden', 'true');
					child.setAttribute('inert', '');
				}
			});
		}

		this.dialog.removeAttribute('inert');

		if (prefersReducedMotion) {
			this.dialog.dispatchEvent(dialogOpenedEvent);
		}

		this.isOpen = true;
		if (!this.supportsNativeDialogElement) {
			this.dialogBackdrop.removeAttribute('inert');
		}

		// set focus
		const focusTarget = this.dialog.querySelector('[autofocus]');
		if (focusTarget) focusTarget.focus();

		if (typeof onOpen === 'function') {
			onOpen();
		}
	}

	// click outside the dialog handler
	lightDismiss({ target: element }) {
		if (!this.supportsNativeDialogElement) {
			if (element.className === 'modal__backdrop') this.closeDialog();
		} else {
			this.closeDialog();
		}
	}

	closeDialog(onClose = this.onClose) {
		if (!this.dialog) return;
		document.removeEventListener('keydown', this.keyDownHandler.bind(this));
		if (this.supportsNativeDialogElement) {
			this.dialog.close();
		} else {
			if (this.dialogBackdrop || document.querySelector('.modal__backdrop')) {
				this.dialogBackdrop.setAttribute('inert', '');
			}
			[...document.body.children].forEach((child) => {
				if (
					!child.classList.contains('modal') &&
					!child.classList.contains('modal__backdrop') &&
					child.tagName !== 'SCRIPT'
				) {
					child.removeAttribute('aria-hidden');
					child.removeAttribute('inert');
				}
			});
		}

		window.dispatchEvent(dialogClosingEvent);
		this.dialog.setAttribute('inert', '');
		this.dialog.setAttribute('aria-hidden', 'true');
		this.dialog.removeAttribute('aria-live');

		if (!prefersReducedMotion) {
			const d = this.dialog;
			d.addEventListener(
				'animationend',
				function () {
					window.dispatchEvent(dialogClosedEvent);
					d.remove();
				},
				{ once: true }
			);
		}

		this.dialog.classList.remove(MODAL_OPEN_CLASS);
		document.body.classList.remove(BODY_MODAL_CLASS);

		if (prefersReducedMotion) {
			window.dispatchEvent(dialogClosedEvent);
			this.dialog.remove();
		}

		if (typeof onClose === 'function') {
			onClose();
		}

		// remove the dialog from the queue
		window.App.modalQueue = false;
		// garbage collection
		this.dialog = null;
	}

	addDialogCloseEventListeners() {
		if (this.supportsNativeDialogElement) {
			this.dialog.addEventListener('click', (e) => {
				if (e.target.nodeName === 'DIALOG') {
					this.lightDismiss(e);
				}
			});
		} else {
			this.dialogBackdrop.addEventListener(
				'click',
				() => {
					this.lightDismiss({ target: this.dialogBackdrop });
				},
				{ once: true }
			);
		}

		document.addEventListener('keydown', this.keyDownHandler.bind(this));

		this.dialog
			.querySelectorAll('[js-element~="modalClose"]')
			.forEach((trigger) => {
				trigger.addEventListener(
					'click',
					() => {
						this.closeDialog();
					},
					{ once: true }
				);
			});
	}

	keyDownHandler(event) {
		if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
			this.closeDialog();
		}
	}
}
