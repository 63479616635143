function init() {
	const springboardLinks = document.querySelectorAll(
		'[js-module~="springboardTracking"]'
	);

	if (!springboardLinks.length) return;

	springboardLinks.forEach((link) => {
		link.addEventListener(
			'click',
			(e) => {
				sendSpringboardTracking(e.currentTarget);
			},
			{ passive: true }
		);
	});
}

function sendSpringboardTracking(link) {
	const linkData = JSON.parse(link.dataset.tracking);

	window.dataLayer.push({
		springboard: {
			screen_name: linkData.screenName,
			springboard_name: linkData.springboardName,
		},
		event: 'springboard_click',
	});
}

export default {
	init,
};
