import tracking from '../tracking/tracking.logic';

let observer;

function init() {
	const trackedSwimlaneDetails = document.querySelectorAll(
		'[js-module~="detailTracking"]'
	);

	if (trackedSwimlaneDetails.length) {
		createTrackingObserver(trackedSwimlaneDetails);
	}
}

function createTrackingObserver(elements) {
	if (
		'IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'isIntersecting' in window.IntersectionObserverEntry.prototype
	) {
		observer = new IntersectionObserver(onIntersection, {
			threshold: 1,
		});

		elements.forEach((el) => {
			observer.observe(el);
		});
	}
}

function onIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			tracking.init(entry.target.closest('.swimlane-detail-list'));
			// once observed and tracked, now stop observing that entry
			observer.unobserve(entry.target);
		}
	});
}

export default {
	init,
};
