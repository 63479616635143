import DropDown from '../../classes/dropDown/dropDown';

function init() {
	const select = document.querySelectorAll('[js-module~="customSelect"]');

	select.forEach((el) => {
		new DropDown(el);
	});
}

export default {
	init,
};
