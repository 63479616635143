import profileTracking from '../profiles/profiles.tracking';
import kidslock from '../kidslock/kidslock';

let checkboxes,
	checkbox,
	activeElements,
	dropdown,
	toggle,
	profilesAlreadyFetched,
	fallbacks,
	profileContainers,
	subscriptionContainers;

function init() {
	// we have multiple user-dropdowns (one for mobile, one for desktop)
	checkboxes = document.querySelectorAll('[js-module~="userDropdown"]');
	if (!checkboxes.length) return;

	profilesAlreadyFetched = false;
	fallbacks = document.querySelectorAll('.user-dropdown__switcher-fallback');

	profileContainers = document.querySelectorAll(
		'.user-dropdown__profiles-container'
	);

	subscriptionContainers = document.querySelectorAll(
		'[js-element~="subscriptionPlaceholder"]'
	);

	checkboxes.forEach((checkbox) => {
		checkbox.addEventListener('change', onStateChange, { passive: true });
	});
}

function onStateChange() {
	toggle = this.parentElement;
	dropdown = toggle.querySelector('.user-dropdown');
	checkbox = this;

	if (checkbox.checked) {
		activeElements = this.closest('.nav').querySelectorAll('.is-active');
		checkbox.setAttribute('aria-expanded', 'true');
		showDropdown();
		window.addEventListener('click', globalClose, { passive: true });
	} else {
		checkbox.setAttribute('aria-expanded', 'false');
		hideDropdown();
	}
}

function showDropdown() {
	toggle.classList.add('is-active');

	// fetch the profiles
	// only for the first time
	if (!profilesAlreadyFetched && dropdown.dataset.profileUrl) {
		getProfiles();
	}

	activeElements.forEach((el) => {
		el.classList.remove('is-active');
	});

	dropdown.setAttribute('aria-hidden', 'false');
	dropdown.removeAttribute('tabindex');
}

function hideDropdown() {
	toggle.classList.remove('is-active');

	activeElements.forEach(function (el) {
		el.classList.add('is-active');
	});

	dropdown.setAttribute('aria-hidden', 'true');
	dropdown.setAttribute('tabindex', '-1');
}

async function getProfiles() {
	const response = await fetch(dropdown.dataset.profileUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/xml',
			'X-Requested-With': 'XMLHttpRequest',
		},
	});

	if (!response.ok) {
		showError();
		return;
	}

	const content = await response.text();
	onSuccess(content);
}

function onSuccess(response) {
	fallbacks.forEach((fb) => {
		fb.classList.remove('is-visible');
	});

	const responseHTML = new DOMParser().parseFromString(response, 'text/html');

	const profilesResponse = responseHTML.querySelector(
		'[js-element~="profilesResponse"]'
	);

	const subscriptionResponse = responseHTML.querySelector(
		'[js-element~="subscriptionResponse"]'
	);

	profileContainers.forEach((container) => {
		container.classList.remove('is-hidden');
		container.innerHTML = profilesResponse.innerHTML;
	});

	if (subscriptionContainers && subscriptionResponse) {
		subscriptionContainers.forEach((container) => {
			container.innerHTML = subscriptionResponse.innerHTML;
			container.classList.remove('user-dropdown__item--dummy');
		});
	}

	// only once ;-)
	profilesAlreadyFetched = true;
	// attach eventlisteners for tracking and kidslock
	profileTracking.init();
	kidslock.init();
}

function showError() {
	fallbacks.forEach((fb) => {
		if (!fb.classList.contains('is-visible')) {
			fb.classList.add('is-visible');
		}
	});

	profileContainers.forEach((container) => {
		if (!container.classList.contains('is-hidden')) {
			container.classList.add('is-hidden');
		}
	});
}

function globalClose(e) {
	const openDropdown = document.querySelector(
		'.user-dropdown[aria-hidden="false"]'
	);

	if (!!openDropdown && e.target === openDropdown) {
		checkbox.checked = false;

		checkbox.dispatchEvent(
			new Event('change', {
				cancelable: true,
				bubbles: true,
			})
		);

		window.removeEventListener('click', globalClose, { passive: true });
	}
}

export default {
	init,
};
