function init() {
	const collectionLinks = document.querySelectorAll(
		'[js-module~="collectionTracking"]'
	);

	if (!collectionLinks.length) return;

	collectionLinks.forEach((link) => {
		link.addEventListener(
			'click',
			(e) => {
				sendCollectionTracking(e.currentTarget);
			},
			{ passive: true }
		);
	});
}

function sendCollectionTracking(link) {
	const linkData = JSON.parse(link.dataset.tracking);

	window.dataLayer.push({
		collection: {
			id: linkData.id,
			name: linkData.name,
		},
		event: 'collection_click',
	});
}

export default {
	init,
};
