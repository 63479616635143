function init() {
	window.addEventListener('keydown', handleFirstTab, { passive: true });
}

function handleFirstTab(e) {
	if (e.key === 'Tab') {
		document.body.classList.add('is-tabbing');
		window.removeEventListener('keydown', handleFirstTab, { passive: true });
		window.addEventListener('mousedown', handleMouseDownOnce, {
			passive: true,
		});
	}
}

function handleMouseDownOnce() {
	document.body.classList.remove('is-tabbing');
	window.removeEventListener('mousedown', handleMouseDownOnce, {
		passive: true,
	});
	window.addEventListener('keydown', handleFirstTab, { passive: true });
}

export default {
	init,
};
