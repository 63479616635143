import Modal from '../../classes/modal/modal';

function init() {
	const loginButtons = document.querySelectorAll(
		'[js-module~="loginRedirect"]'
	);
	const loginModalButtons = document.querySelectorAll(
		'[js-module~="loginModal"]'
	);

	loginButtons.forEach((loginButton) => {
		loginButton.addEventListener('click', setRedirectCookie, false);
	});

	loginModalButtons.forEach((button) => {
		button.addEventListener('click', (e) => {
			showLoginModal();
			e.preventDefault();
		});
	});
}

function setRedirectCookie() {
	document.cookie =
		'lfvp_auth.redirect_uri=' + window.location.href + ';expires=0; path=/';
}

function showLoginModal() {
	const html = document
		.querySelector('#loginModalTemplate')
		?.content.cloneNode(true);

	if (!html) return;

	new Modal('Shortform', {
		message: html,
	});
}

export default {
	init,
};
