import avatar from './modules/avatar/avatar';
import banner from './modules/banner/banner.video';
import blockingOverlays from './modules/blocking-overlays/blocking-overlays';
import browserDetect from './modules/browser-detect/browser-detect';
import collectionClickTracking from './modules/collections/collections.tracking';
import cookieBanner from './modules/cookie-banner/cookie-banner';
import cookie from './modules/cookies/cookies';
import customSelect from './modules/custom-select/custom-select';
import detailMenu from './modules/detail-menu/detail.menu';
import detail from './modules/detail/detail';
import downloads from './modules/downloads/downloads';
import geoblock from './modules/geoblock/geoblock'; // for Streamz
import jsClass from './modules/js-class/js-class';
import kidslock from './modules/kidslock/kidslock';
import konami from './modules/konami/konami';
import liveUpdate from './modules/live-update/live-update';
import login from './modules/login/login';
import logout from './modules/logout/logout';
import marketing from './modules/marketing/marketing';
import mobileDetect from './modules/mobile-detect/mobile-detect';
import myList from './modules/my-list/my-list';
import nav from './modules/nav/nav';
import player from './modules/player/player.module';
import popover from './modules/popover/popover';
import privacyGate from './modules/privacy-gate/privacy-gate';
import profiles from './modules/profiles/profiles';
import search from './modules/search/search';
import session from './modules/session/session';
import shortformFeed from './modules/short-form/short-form';
import springboardTracking from './modules/springboard/springboard.tracking';
import sw from './modules/sw/sw';
import swimlane from './modules/swimlane/swimlane';
import tabFocus from './modules/tab-focus/tab-focus';
import teaserEdit from './modules/teaser-edit/teaser-edit';
import tiers from './modules/tiers/tiers';
import top10 from './modules/top10/top10';
import tracking from './modules/tracking/tracking';
import tvguide from './modules/tvguide/tvguide';
import userDropdown from './modules/user-dropdown/user-dropdown';

function init() {
	window.App = window.App || {};

	mobileDetect.init(); // needs to come fist, trumps all other modals
	privacyGate.init();
	marketing.init();

	avatar.init();
	banner.init();
	blockingOverlays.init();
	browserDetect.init();
	collectionClickTracking.init();
	customSelect.init();
	cookieBanner.init();
	cookie.init();
	detail.init();
	detailMenu.init();
	downloads.init();
	geoblock.init();
	jsClass.init();
	kidslock.init();
	liveUpdate.init();
	login.init();
	logout.init();
	myList.init();
	nav.init();
	player.init();
	popover.init();
	profiles.init();
	search.init();
	session.init();
	shortformFeed.init();
	springboardTracking.init();
	sw.init();
	swimlane.init();
	tabFocus.init();
	teaserEdit.init();
	tiers.init();
	top10.init();
	tracking.init();
	tvguide.init();
	userDropdown.init();
	konami.init();
}

document.addEventListener('DOMContentLoaded', init, false);
