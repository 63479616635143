let tiers = null;
let scheduleButtons = null;
let planButtons = null;
let selectedPlan = 'streamz'; // streamzbasic, streamz, streamzplus
let selectedSchedule = 'yearly';

const promotions = new Map();
// any promotions active? add them to the map
// promotions.set('yearly', ['streamz']);

const streamzPremiumPlusBasePrice = 20.95;
const streamzPremiumBasePrice = 12.95;
const streamzBasicBasePrice = 7.95;

const pricingTable = {
	monthly: [
		// streamzpremiumplus
		{
			priceAfterDiscount: streamzPremiumPlusBasePrice,
			priceBeforeDiscount: null,
		},
		// streamzpremium
		{
			priceAfterDiscount: streamzPremiumBasePrice,
			priceBeforeDiscount: null,
		},
		// streamzbasic
		{
			priceAfterDiscount: streamzBasicBasePrice,
			priceBeforeDiscount: null,
		},
	],
	'6monthly': [
		// 'streamzpremiumplus'
		{
			priceAfterDiscount: 17.95,
			priceBeforeDiscount: streamzPremiumPlusBasePrice,
		},
		// streamzpremium
		{
			priceAfterDiscount: 10.95,
			priceBeforeDiscount: streamzPremiumBasePrice,
		},
		// streamzbasic
		{
			priceAfterDiscount: 6.95,
			priceBeforeDiscount: streamzBasicBasePrice,
		},
	],
	yearly: [
		// streamzpremiumplus
		{
			priceAfterDiscount: 15.95,
			priceBeforeDiscount: streamzPremiumPlusBasePrice,
		},
		// streamzpremium
		{
			priceAfterDiscount: 9.95,
			priceBeforeDiscount: streamzPremiumBasePrice,
		},
		// streamzbasic
		{
			priceAfterDiscount: 5.95,
			priceBeforeDiscount: streamzBasicBasePrice,
		},
	],
};

function init() {
	tiers = document.querySelector('[js-module~="tiers"]');

	if (!tiers) return;

	scheduleButtons = tiers.querySelectorAll('input[name="tiersSchedule"]');
	planButtons = tiers.querySelectorAll('input[name="tiersPlan"]');

	scheduleButtons.forEach((schedule) => {
		schedule.addEventListener(
			'change',
			() => {
				changeHandler(schedule);
			},
			{ passive: true }
		);
	});

	planButtons.forEach((plan) => {
		plan.addEventListener(
			'change',
			() => {
				changeHandler(plan);
			},
			{ passive: true }
		);
	});
}

function changeHandler(el) {
	if (el.name === 'tiersSchedule') {
		scheduleButtons.forEach((schedule) => {
			schedule.closest('label').classList.remove('is-active');
		});
		selectedSchedule = el.value;
		tiers.dataset.schedule = el.value;
	} else {
		planButtons.forEach((plan) => {
			plan.closest('label').classList.remove('is-active');
		});
		selectedPlan = el.value;
		tiers.dataset.plan = el.value;
	}

	tiers.querySelectorAll('.tiers__prices td').forEach((price) => {
		if (promotions.has(selectedSchedule)) {
			promotions.get(selectedSchedule).forEach((plan) => {
				if (
					price.classList.contains(plan) &&
					!price.classList.contains('is-promo')
				) {
					price.classList.add('is-promo');
				}
			});
		} else {
			if (price.classList.contains('is-promo')) {
				price.classList.remove('is-promo');
			}
		}
	});

	el.closest('label').classList.add('is-active');
	setPricing();
	setOnboardingUrl();
}

function setPricing() {
	const afterDiscount = tiers.querySelectorAll(
		'[js-element~="tiersAfterDiscount"]'
	);
	const beforeDiscount = tiers.querySelectorAll(
		'[js-element~="tiersBeforeDiscount"]'
	);

	afterDiscount.forEach((price, index) => {
		const amount = pricingTable[selectedSchedule][index].priceAfterDiscount;
		if (typeof amount === 'number') {
			price.classList.remove('is-not-applicable');
			price.innerHTML = new Intl.NumberFormat('nl-BE', {
				style: 'currency',
				currency: 'EUR',
			}).format(amount);
		} else {
			if (!price.classList.contains('is-not-applicable')) {
				price.classList.add('is-not-applicable');
			}
			price.innerHTML = amount;
		}
	});
	beforeDiscount.forEach((price, index) => {
		const amount = pricingTable[selectedSchedule][index].priceBeforeDiscount;
		if (typeof amount === 'number') {
			price.innerHTML = new Intl.NumberFormat('nl-BE', {
				style: 'currency',
				currency: 'EUR',
			}).format(amount);
		} else {
			price.innerHTML = '';
		}
	});
}

function setOnboardingUrl() {
	const onboardingButton = document.querySelector(
		'[js-element~="tierOnboarding"]'
	);

	const onboardingUrl = new URL(onboardingButton.href);
	const params = onboardingUrl.searchParams;

	params.set(
		'plan',
		selectedPlan + (selectedSchedule === 'monthly' ? '' : selectedSchedule)
	);

	onboardingButton.href =
		onboardingUrl.origin + onboardingUrl.pathname + '?' + params.toString();

	onboardingButton.textContent = `${window.i18n.tiers.cta} ${window.i18n.tiers[selectedPlan]} ${window.i18n.tiers[selectedSchedule]}`;
}

export default {
	init,
};
