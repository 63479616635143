import detailMenuInteraction from './detail.menu.interaction';
import seasonPicker from './detail.seasonpicker';

function init() {
	detailMenuInteraction.init();
	seasonPicker.init();
}

export default {
	init,
};
