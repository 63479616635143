function init() {
	const logoutButtons = document.querySelectorAll('[js-module~="logout"]');
	logoutButtons.forEach((logoutButton) => {
		logoutButton.addEventListener('click', ssoLogout);
	});
}

function ssoLogout(event) {
	if (window.ssoLogout !== null && window.ssoConfig !== null) {
		window.ssoLogout.logout(window.ssoConfig);
	}

	event.preventDefault();
}

export default {
	init,
};
