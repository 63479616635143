import Modal from '../../classes/modal/modal';
import browsersniffing from '../../utils/browsersniffing/browsersniffing';
import { isNewSession } from '../../utils/session/session';

function init() {
	// We show the modal with Geoblock warning ONLY on Streamz
	if (
		!browsersniffing.detectMobileOS() &&
		isNewSession() &&
		!document.querySelectorAll('[js-module~="noSessionModal"]').length &&
		document.querySelectorAll('.is-geoblock-active').length
	) {
		const html = document
			.querySelector('#geoBlockModalTemplate')
			?.content.cloneNode(true);

		if (!html) return;

		new Modal('Geo', {
			message: html,
		});
	}
}

export default {
	init,
};
