function GetBrowser() {
	const ua = navigator.userAgent;
	const versionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;
	const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

	/* The order of descriptors is important because a parser goes through them one by one
	 * in course. For example, if you insert Chrome's descriptor as the first one,
	 * more then a half of browsers will be described as Chrome, because they will pass
	 * the Chrome descriptor's test.
	 */
	const browsers = [
		{
			// Opera < 13.0
			test: /opera/i,
			name: 'Opera',
			version:
				getFirstMatch(versionIdentifier, ua) ||
				getFirstMatch(/(?:opera)[\s/](\d+(\.?_?\d+)+)/i, ua),
		},
		{
			// Opera > 13.0
			test: /opr\/|opios/i,
			name: 'Opera',
			version:
				getFirstMatch(/(?:opr|opios)[\s/](\S+)/i, ua) ||
				getFirstMatch(versionIdentifier, ua),
		},
		{
			// Internet Explorer
			test: /msie|trident/i,
			name: 'Internet Explorer',
			version: getFirstMatch(/(?:msie |rv:)(\d+(\.?_?\d+)+)/i, ua),
		},
		{
			// Microsoft Edge
			test: /edg([ea]|ios)/i,
			name: 'Microsoft Edge',
			version: getSecondMatch(/edg([ea]|ios)\/(\d+(\.?_?\d+)+)/i, ua),
		},
		{
			// Firefox
			test: /firefox|iceweasel|fxios/i,
			name: 'Firefox',
			version: getFirstMatch(
				/(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i,
				ua
			),
		},
		{
			// Chromium
			test: /chromium/i,
			name: 'Chromium',
			version:
				getFirstMatch(/(?:chromium)[\s/](\d+(\.?_?\d+)+)/i, ua) ||
				getFirstMatch(versionIdentifier, ua),
		},
		{
			// Chrome
			test: /chrome|crios|crmo/i,
			name: 'Chrome',
			version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.?_?\d+)+)/i, ua),
		},
		{
			// Safari
			test: /safari|applewebkit/i,
			name: 'Safari',
			version: getFirstMatch(versionIdentifier, ua),
		},
	];

	const systems = [
		{
			// Windows
			test: /windows/i,
			name: 'Windows',
			version: getWindowsVersionName(
				getFirstMatch(/Windows ((NT|XP)( \d\d?.\d)?)/i, ua)
			),
		},
		{
			// MacOS
			test: /macintosh/i,
			name: 'Mac',
			version: getFirstMatch(/mac os x (\d+(\.?_?\d+)+)/i, ua).replace(
				/[_\s]/g,
				'.'
			),
		},
	];

	function getFirstMatch(regexp, ua) {
		const match = ua.match(regexp);
		return (match && match.length > 0 && match[1]) || '';
	}

	function getSecondMatch(regexp, ua) {
		const match = ua.match(regexp);
		return (match && match.length > 1 && match[2]) || '';
	}

	function getWindowsVersionName(version) {
		switch (version) {
			case 'NT':
				return 'NT';
			case 'XP':
				return 'XP';
			case 'NT 5.0':
				return '2000';
			case 'NT 5.1':
				return 'XP';
			case 'NT 5.2':
				return '2003';
			case 'NT 6.0':
				return 'Vista';
			case 'NT 6.1':
				return '7';
			case 'NT 6.2':
				return '8';
			case 'NT 6.3':
				return '8.1';
			case 'NT 10.0':
				return '10';
			default:
				return undefined;
		}
	}

	function getBrowserName() {
		for (let i = 0; i < browsers.length; i++) {
			const regexp = browsers[i].test;
			const browser = regexp.test(ua);

			if (browser) {
				return browsers[i].name;
			}
		}
	}

	function getBrowserVersion() {
		for (let i = 0; i < browsers.length; i++) {
			const regexp = browsers[i].test;
			const browser = regexp.test(ua);

			if (browser) {
				return browsers[i].version; // stop after the first result
			}
		}
	}

	function getOsName() {
		if (ua.includes('HeadlessChrome')) return 'HeadlessChrome';

		for (let i = 0; i < systems.length; i++) {
			const regexp = systems[i].test;
			const os = regexp.test(ua);

			if (os) {
				return systems[i].name; // stop after the first result
			}
		}
	}

	function getDeviceCategory() {
		// Check if its a mobile device on the mobi tag insade the user agent and if its an Apple touch device of .
		return ua.includes('Mobi') ||
			ua.includes('mobi') ||
			(ua.includes('Intel Mac OS X') && isTouchDevice)
			? 'Mobile'
			: 'Desktop';
	}

	function getMobileOS() {
		if (getDeviceCategory() === 'Mobile') {
			if (ua.includes('Windows')) return 'Windows';
			if (ua.includes('Android')) return 'AndroidOS';
			if (
				(ua.includes('Intel Mac OS X') && isTouchDevice) ||
				ua.includes('CPU OS 13')
			)
				return 'iOS';
			return true; // fallback for exotic mobile OS
		}
		return false;
	}

	return {
		desktopOs: getOsName(),
		browser: getBrowserName(),
		user_agent: ua,
		mobileOS: getMobileOS(),
		deviceCategory: getDeviceCategory(),
		version: getBrowserVersion(),
	};
}

export default GetBrowser;
