export default class Anchors {
	options = {
		linksSelector: '[js-element~="anchorsLink"]',
		sectionSelector: '[js-element~="anchorsSection"]',
		offset: document.querySelectorAll('[js-element~="anchorsStickyNav"]').length
			? window
					.getComputedStyle(
						document.querySelector('[js-element~="anchorsStickyNav"]')
					)
					.getPropertyValue('top')
			: 0,
	};

	constructor(element, config) {
		if (!element) throw Error('No anchors element provided');

		this.element = element;
		this.options = { ...this.options, ...config };
		this.anchors = this.element.querySelectorAll(this.options.linksSelector);

		if (!this.anchors.length) return;

		this.sections = this.element.querySelectorAll(this.options.sectionSelector);
		this.ACTIVE_ATTRIBUTE = 'aria-current';
		this.clickHandler = this.anchorClickHandler.bind(this);
		this.scrollHandler = this.scrollHandler.bind(this);
		this.offset = isNaN(parseInt(this.options.offset, 10))
			? 0
			: parseInt(this.options.offset, 10);
		this.activeLink = null;
		this.forcedLink = false;
		this.itemsInViewport = [0];
		this.scrollHeight = Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight
		);
		this.maxScroll = this.offset - 2 + this.scrollHeight - window.innerHeight; // -2 to compensate for subpixels and rounding up

		this.process();
	}

	destroy() {
		this.removeAnchorLinksEventListeners();
		this.removeScrollEventListener();

		this.activeLink.removeAttribute(this.ACTIVE_ATTRIBUTE);
	}

	process() {
		for (let i = 0; i < this.anchors.length; i++) {
			this.anchors[i].removeAttribute(this.ACTIVE_ATTRIBUTE);
		}

		if (window.location.hash) {
			this.forcedLink = true; // so the scrolling doesn't override the active link
			this.setActiveAnchor(
				this.element.querySelector('a[href="' + window.location.hash + '"]')
			);
		}

		// check if the page has a scroll position. (after a reload or when using the back button)
		if (!window.location.hash) {
			this.scrollHandler();
		}

		this.addAnchorLinksEventListeners();

		this.addScrollEventListener();
	}

	addAnchorLinksEventListeners() {
		this.element.addEventListener('click', this.clickHandler, false);
	}

	removeAnchorLinksEventListeners() {
		this.element.removeEventListener('click', this.clickHandler, false);
	}

	anchorClickHandler(event) {
		this.forcedLink = true; // so the scrolling doesn't override the clicked link
		const anchor = event.target;

		if (anchor.tagName.toLowerCase() !== 'a') return;

		this.setActiveAnchor(anchor);
		const scrollBehavior =
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true
				? 'auto'
				: 'smooth';

		document.querySelector(anchor.hash).scrollIntoView({
			behavior: scrollBehavior,
		});
		event.preventDefault();

		window.history.replaceState(
			{
				activeUrl: anchor.href,
			},
			'',
			anchor.href
		);
	}

	setActiveAnchor(link) {
		this.anchors.forEach((anchor) => {
			anchor.removeAttribute(this.ACTIVE_ATTRIBUTE);
			anchor.blur();
		});

		link.setAttribute(this.ACTIVE_ATTRIBUTE, 'true');
		this.activeLink = link;
	}

	scrollHandler() {
		if (this.forcedLink) {
			this.forcedLink = false; // reset
			return;
		}

		// if the first item is still in the viewport
		if (
			this.sections[0].getBoundingClientRect().top >= this.offset ||
			this.sections[0].getBoundingClientRect().bottom > this.offset
		) {
			this.setActiveAnchor(this.anchors[0]);
			return;
		}

		// calculate which section is still visible
		for (let i = 0, l = this.sections.length; i < l; i++) {
			const isActiveSection =
				(this.activeLink !== this.anchors[i] &&
					(typeof this.sections[i + 1] === 'undefined' ||
						(this.sections[i + 1].getBoundingClientRect().top > this.offset &&
							this.sections[i + 1].getBoundingClientRect().bottom >
								window.innerHeight))) ||
				(this.sections[i].getBoundingClientRect().bottom > this.offset &&
					this.sections[i].getBoundingClientRect().top < this.offset) ||
				(this.sections[i].getBoundingClientRect().top >= this.offset &&
					this.sections[i].getBoundingClientRect().bottom >
						window.innerHeight) ||
				(this.sections[i].getBoundingClientRect().top >= this.offset &&
					this.sections[i].getBoundingClientRect().bottom <=
						window.innerHeight);

			if (isActiveSection) {
				this.setActiveAnchor(this.anchors[i]);
				return;
			}
		}
	}

	addScrollEventListener() {
		let isScrolling,
			that = this;

		window.addEventListener(
			'scroll',
			function () {
				window.clearTimeout(isScrolling);

				isScrolling = window.setTimeout(function () {
					that.scrollHandler();
				}, 100);
			},
			{ passive: true }
		);
	}

	removeScrollEventListener() {
		let isScrolling,
			that = this;

		window.removeEventListener(
			'scroll',
			function () {
				window.clearTimeout(isScrolling);

				isScrolling = window.setTimeout(function () {
					that.scrollHandler();
				}, 100);
			},
			{ passive: true }
		);
	}

	removeForcedLink() {
		this.forcedLink = false;
	}
}
