import GetBrowser from './detect-browser';

const browza = new GetBrowser();

// detects mobile devices: phones and tablets
function detectMobileOS() {
	return browza.mobileOS;
}

function detectDeviceCategory() {
	return browza.deviceCategory;
}

function detectDesktopOS() {
	return browza.desktopOs;
}

function detectBrowserName() {
	return browza.browser;
}

function detectBrowserVersion() {
	return browza.version;
}

/*
 * Unsupported browsers for VTM GO:
 * (because of unsupported DRM feature in the player)
 * and we want to be modern and safe
 * see package.json for the most up2date list
 * ================================
 * Google Chrome < 99
 * Edge < 99
 * Firefox < 78
 * Opera < 85
 * Safari < 13.1 (on Mac)
 * All IE
 * Safari on Windows (all)
 */
function detectSupportedBrowser() {
	const { desktopOs, version, browser: name } = browza;
	const browserVersionShort = version.split('.')[0];

	if (browza.user_agent === 'HeadlessChrome') return true;

	if (
		name === 'Internet Explorer' ||
		(name === 'Microsoft Edge' && browserVersionShort < 99) ||
		(name === 'Chrome' && browserVersionShort < 99) ||
		(name === 'Firefox' && browserVersionShort < 78) ||
		(name === 'Opera' && browserVersionShort < 85) ||
		(name === 'Safari' && browserVersionShort < 13.1) ||
		(name === 'Safari' && desktopOs === 'Windows')
	) {
		return false;
	}

	return true;
}

const browserSniffing = {
	detectMobileOS,
	detectDesktopOS,
	detectBrowserName,
	detectBrowserVersion,
	detectDeviceCategory,
	detectSupportedBrowser,
};

export default browserSniffing;
