import browsersniffing from '../../utils/browsersniffing/browsersniffing';

function init() {
	if (browsersniffing.detectDesktopOS()) {
		const os = browsersniffing.detectDesktopOS();
		const browser = browsersniffing.detectBrowserName();

		document.documentElement.classList.add(os.toLowerCase(), browser);
	}

	if (isDateInputSupported()) {
		const datepickerLabel = document.querySelector(
			'[js-module~="datepickerNotSupported"]'
		);

		if (datepickerLabel) {
			datepickerLabel.style.display = 'none';
		}
	}
}

function isDateInputSupported() {
	const input = document.createElement('input');
	const value = 'a';

	input.setAttribute('type', 'date');
	input.setAttribute('value', value);

	return input.value !== value;
}

export default {
	init,
	isDateInputSupported,
};
