/*
 * LRU : Least-Recent-Used
 * If the amount of items in the case exceed the `capacity`, we delete
 * the one that is least recently used.
 * `update` and `put` will both update the cache
 * The other methods will return values but not update the cache
 * inspired by https://dev.to/seanwelshbrown/implement-a-simple-lru-cache-in-javascript-4o92
 */
export default class LRU {
	//set default capacity of 10 if capacity is not passed.
	constructor(capacity = 10, { onDelete } = {}) {
		this.cache = new Map();
		this.capacity = capacity;
		this.onDelete = onDelete;
	}

	update(key) {
		if (!this.cache.has(key)) return -1;

		let val = this.cache.get(key);

		this.cache.delete(key);
		this.cache.set(key, val);
	}

	put(key, value) {
		this.cache.delete(key);

		if (this.cache.size === this.capacity) {
			const oldest = this.cache.keys().next().value;
			if (typeof this.onDelete === 'function') {
				this.onDelete(this.cache.get(oldest));
			}
			this.cache.delete(oldest);
			this.cache.set(key, value);
		} else {
			this.cache.set(key, value);
		}
	}

	// Implement LRU/MRU retrieval methods
	getLeastRecent() {
		return Array.from(this.cache)[0];
	}

	getMostRecent() {
		return Array.from(this.cache)[this.cache.size - 1];
	}

	getPreviousRecent() {
		if (this.cache.size >= 2) {
			return Array.from(this.cache)[this.cache.size - 2];
		}

		return Array.from(this.cache)[this.cache.size - 1];
	}

	getCacheValues() {
		return Array.from(this.cache.values());
	}
}
