import Modal from '../../classes/modal/modal';

function init() {
	const html = document
		.querySelector('#downloadsModalTemplate')
		?.content.cloneNode(true);

	if (!html) return;

	document.querySelectorAll('[js-module~="downloads"]').forEach((link) => {
		link.addEventListener('click', (e) => {
			new Modal(
				'Downloads',
				{
					message: html,
					closeButton: false,
				},
				{},
				['modal--transparent']
			);
			e.preventDefault();
		});
	});
}

export default {
	init,
};
