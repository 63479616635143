import Modal from '../../classes/modal/modal';

let triggerForm,
	frm,
	field,
	expression,
	expressions,
	solutionfield,
	expressionfield,
	randomExpression,
	success,
	answer,
	msg,
	retry,
	mathExpression;

function init() {
	document
		.querySelectorAll('[js-module~="kidsLock"]')
		.forEach(function (trigger) {
			trigger.addEventListener('click', function (e) {
				const message = document
					.querySelector('#kidsLockModalTemplate')
					?.content.cloneNode(true);

				if (!message) return;

				new Modal(
					'KidsLock',
					{
						message,
						closeButton: false,
					},
					{
						onClose: kidslockCancel,
						onOpen: kidsLockBootstrap,
					},
					['modal--transparent', 'kidslock']
				);

				triggerForm = this.closest('form');

				e.preventDefault();
			});
		});
}

function kidsLockBootstrap() {
	frm = document.querySelector('[js-element~="kidsLockForm"]');
	field = frm.querySelector('[js-element~="kidsLockSolutionField"]');
	solutionfield = frm.querySelector('[js-element~="kidsLockSolution"]');
	expressionfield = frm.querySelector('[js-element~="kidsLockQuestion"]');
	success = frm.querySelector('[js-element~="kidsLockSucces"]');
	msg = document.querySelector('[js-element~="kidsLockMssg"]');
	retry = msg.querySelector('[js-element~="kidsLockRetry"]');
	mathExpression = frm.querySelector('[js-element~="kidsLockExpression"]');

	expressions = [
		{
			expression: '5 &times; 7',
			result: '35',
		},
		{
			expression: '3 &times; 8',
			result: '24',
		},
		{
			expression: '36 &divide; 3',
			result: '12',
		},
		{
			expression: '5 &times; 5',
			result: '25',
		},
		{
			expression: '4 &times; 7',
			result: '28',
		},
		{
			expression: '8 &times; 6',
			result: '48',
		},
		{
			expression: '26 &divide; 2',
			result: '13',
		},
		{
			expression: '6 &times; 9',
			result: '54',
		},
		{
			expression: '9 &times; 7',
			result: '63',
		},
	];

	window.dataLayer = window.dataLayer || [];
	reportKidsLockView();
	showNewExpression();

	if (field) {
		field.addEventListener('keyup', checkValue, false);
	}

	retry.addEventListener('click', _retry, false);
}

function _retry() {
	reportKidsLockRetry(expression);
	showNewExpression();
}

function checkValue(e) {
	const key = parseInt(e.key, 10);

	// only allow numbers, including the numpad ones.
	// if it's not a number than do nothing (submit the result)
	if (isNaN(key)) {
		return;
	}

	const val = field.value;
	const solution = solutionfield.value;
	const trackingArgs = {};

	expression = expressionfield.value;

	if (val !== '' && val.length > 1) {
		answer = val;
		trackingArgs.answer = answer;
		trackingArgs.question = expression;

		if (answer === solution) {
			reportKidsLockCorrectAnswer(trackingArgs);
			success.removeAttribute('aria-hidden');
			field.setAttribute('aria-hidden', 'true');
			field.value = '';
			submit();
		} else {
			reportKidsLockIncorrectAnswer(trackingArgs);
			showMessage();
		}
	}
}

function submit() {
	if (typeof triggerForm !== 'undefined' && triggerForm) {
		triggerForm.submit();
	} else {
		window.location.href = window.App.adultHome;
	}
}

function kidslockCancel() {
	field.removeEventListener('keyup', checkValue, false);
	retry.removeEventListener('click', _retry, false);

	reportKidsLockCancel(expression);
}

function showNewExpression() {
	if (window.KidsLock) {
		window.KidsLock.classList.remove('anser-is-wrong');
	}
	frm.removeAttribute('aria-hidden');
	msg.setAttribute('aria-hidden', 'true');

	msg.querySelectorAll('button').forEach((button) => {
		button.setAttribute('disabled', 'disabled');
	});

	success.setAttribute('aria-hidden', 'true');

	setKidslockValues();
}

function setKidslockValues() {
	generateNewExpression();

	mathExpression.innerHTML = randomExpression.expression + ' = ?';
	expressionfield.value = randomExpression.expression;
	solutionfield.value = randomExpression.result;

	field.value = '';
	field.focus();
}

function showMessage() {
	if (window.KidsLock) {
		window.KidsLock.classList.add('anser-is-wrong');
	}
	frm.setAttribute('aria-hidden', 'true');
	msg.setAttribute('aria-hidden', 'false');
	msg.querySelectorAll('button').forEach((button) => {
		button.removeAttribute('disabled');
	});
}

function generateNewExpression() {
	randomExpression =
		expressions[Math.floor(Math.random() * expressions.length)];

	return randomExpression;
}

// /////////////////////////////////////////////////////////////////////////
// TRACKING
// /////////////////////////////////////////////////////////////////////////
function reportKidsLockView() {
	window.dataLayer.push({
		event: 'kidslock_view',
	});
}

function reportKidsLockCorrectAnswer(args) {
	window.dataLayer.push({
		event: 'kidslock_correct',
		kidslock: {
			question: args.question,
			answer: args.answer,
		},
	});
}

function reportKidsLockIncorrectAnswer(args) {
	window.dataLayer.push({
		event: 'kidslock_incorrect',
		kidslock: {
			question: args.question,
			answer: args.answer,
		},
	});
}

function reportKidsLockRetry(exp) {
	window.dataLayer.push({
		event: 'kidslock_retry',
		kidslock: {
			question: exp,
		},
	});
}

function reportKidsLockCancel(exp) {
	window.dataLayer.push({
		event: 'kidslock_cancel',
		kidslock: {
			question: exp,
		},
	});
}

export default {
	init,
	bootstrap: kidsLockBootstrap,
};
